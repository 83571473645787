<div class="dynamic-dialog">
  <div class="pt-3">
    <div class="" fxLayoutAlign="start">
      <div class="setup-header-title">
        <h4 style="padding: 0;margin: 0">
          {{ dialogHeader }}
        </h4>
      </div>
    </div>
    <p-divider></p-divider>
    <div  class="project-grid-item-content p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
      <div class="item">
        <span> Project: {{plotInfo?.landProjectName}}</span>
      </div>
      <div class="item">
        <span>Plot No. {{plotInfo?.lotNumber}}</span>
        <span>Price : {{plotInfo?.price}} TSH </span>
      </div>
    </div>
    <p-divider></p-divider>
  </div>
  <div class="dialog-content">
    <p-panel>
      <ng-template pTemplate="header">
        <div *ngFor="let category of categories" class="field-checkbox">
          <p-radioButton  (ngModelChange)="clearData($event)" [inputId]="category.code" name="category" [value]="category.code" [(ngModel)]="selectedCategory"></p-radioButton>
          <label [for]="category.code">&nbsp;&nbsp;<b>{{category.name}}&nbsp;&nbsp;</b></label>
        </div>
      </ng-template>
    </p-panel>
    <div class="grid p-fluid">
      <div class="col-12 md:col-4" *ngIf="selectedCategory == 'OC001'">
        <div class="">
          <p-inputMask [unmask]="true"  fxFlex mask="99999999-99999-99999-99" [(ngModel)]="selectedNin" placeholder="NIN"></p-inputMask>
<!--          <input type="text" [(ngModel)]="selectedNin" pInputText placeholder="NIN">-->
          <button type="button" [disabled]="validateText(selectedNin)!.length!==20" (click)="searchClientDetails()"  pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn"></button>
        </div>
      </div>
      <div class="col-12 md:col-4" *ngIf="selectedCategory == 'OC002'">
        <div class="">
          <p-inputMask [unmask]="true" fxFlex mask="999-999-999" [(ngModel)]="selectedNin" placeholder="TIN"></p-inputMask>
<!--          <input type="text" [(ngModel)]="selectedNin" pInputText placeholder="TIN">-->
          <button type="button" [disabled]="validateText(selectedNin)!.length <=6" (click)="searchClientDetails()"  pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn"></button>
        </div>
      </div>
    </div>
    <br>
    <form [formGroup]="assigmentForm" autocomplete="off" novalidate>
      <div  class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <span class="p-float-label">
              <input
                id="name"
                type="text"
                formControlName="name"
                [readonly]="true"
                pInputText
              />
              <label for="name">Applicant Name</label>
            </span>
        </div>
        <div class="p-field p-col-6">
            <span class="p-float-label">
              <input
                id="phone_number"
                type="text"
                formControlName="phone_number"
                (keypress)="keyPress($event)"
                pInputText
              />
              <label for="phone_number">Phone Number</label>
            </span>
        </div>
        <div class="p-field p-col-6">
            <span class="p-float-label">
              <input
                id="email"
                type="email"
                formControlName="email"
                email="true"
                pInputText
              />
              <label for="email">Email</label>
            </span>
        </div>
        <div class="p-field p-col-12">
          <span class="p-float-label">
              <textarea id="currentAddress"  formControlName="currentAddress" rows="5" cols="30" pInputTextarea></textarea>
              <label for="currentAddress">Applicant Current Address</label>
          </span>
        </div>
      </div>
      <div
        class="px-2 mt-3 mb-4 submit"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <button
          iconPos="left"
          pButton
          type="button"
          icon="pi pi-times"
          label="Close"
          class="p-button-text p-button-sm mx-3"
          (click)="close()"
        ></button>
        <p-button
          label="{{ buttonLabel }}"
          [disabled]="this.assigmentForm.invalid"
          icon="pi pi-save"
          iconPos="right"
          (click)="assign()"
        ></p-button>
      </div>
    </form>
  </div>
</div>
<p-confirmDialog [style]="{width: '40vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog
  key="confirm"
  [style]="{ width: '40vw' }"
  [baseZIndex]="10000"
  [rejectVisible]="false"
  acceptLabel="Ok"
  acceptButtonStyleClass="p-button-text"
></p-confirmDialog>
