<div class="dynamic-dialog">
  <div class="px-0">
    <div class="p-pt-2" fxLayoutAlign="start">
      <div class="setup-header-title">
        <h4>
         Project Extension Details
        </h4>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <div fxFlex="100%" class="form-container">
      <div class="form-container">
        <div class="p-gap-2 p-pt-1" *ngIf="extensionDetails.length > 0">
          <p-table
            [value]="extensionDetails!"
            styleClass="p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>SN</th>
                <th style="width:40%">Lot Use</th>
                <th style="text-align:center">Number of Days</th>
                <th style="text-align: end">Status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex">
              <tr>
                <td>{{ i+1 }}</td>
                <td style="">
                  <b>{{ item?.lotUse }}</b>
                </td>
                <td style="text-align: center">{{ item?.numberOfDay }}</td>
                <td>
                 {{item.status | uppercase}}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            class="p-field p-pt-3 p-pb-0 p-mb-0 p-flex col-12">
            <button
              pButton
              type="button"
              iconPos="left"
              icon="pi pi-times"
              label="Close"
              class="p-button-text p-button-sm"
              (click)="close()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
