import { Event, NavigationEnd, Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { ToastService } from './toast.service';
import { UserStorageService } from './user-storage.service';

@Injectable()
export class NavigationService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>('');
  public isOpen$ = new BehaviorSubject<boolean>(false);
  private isProduction: boolean = environment.isProduction;
  private currentUser: any;
  private url: any;
  private dashboard: any;

  allUserPermissions!: string[];

  constructor(
    private _routerSvc: Router,
    private _toastSvc: ToastService,
    private _authService: AuthService,
    private _permissionsService: NgxPermissionsService,
    private _userStorageService: UserStorageService
  ) {
    this._routerSvc.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });

    this._authService.isEverythingLoaded$.subscribe((flag: any) => {
      if (flag) {
        this._authService.isLoggedIn$.subscribe(($Flag: boolean) => {
          if ($Flag) {
            this.allUserPermissions = this._userStorageService.getPermissions();
            if (this.allUserPermissions != null) {
              this._permissionsService.loadPermissions(this.allUserPermissions);
            }
            this.currentUser = this._userStorageService.getUserDetails();

            const level =
              this.currentUser?.userCurrentSwitchedAccountWeb
                .administrativeAreaId.areaType.areaLevel.id;
            this.dashboard = `main/dashboard`;
            this.url = `main/dashboard`;
            // if (level === 4) {
            //   this.url = `main/pos-management`;
            //   this.dashboard = this.url;
            // } else if (level === 1) {
            //   this.url = `main/pos-management-regional`;
            //   this.dashboard = this.url;
            // } else {
            //   this.url = `main/pos-management-national`;
            //   this.dashboard = `main/`;
            // }
          }
        });
      }
    });
  }

  public closeDrawer() {
    //this.appDrawer.close();
    //this.isOpen$.next(false);
  }

  public openDrawer() {
    this.appDrawer.open();
    this.isOpen$.next(true);
  }

  public drawerToggle() {
    if (this.appDrawer.opened) {
      this.appDrawer.close();
      this.isOpen$.next(false);
    } else {
      this.appDrawer.open();
      this.isOpen$.next(true);
    }
  }

  public getNavLinks() {
    return [
      /**
       * -------------------------------------------------------------------------------
       *  dashboard menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: this.allUserPermissions,
        label: 'Dashboard',
        icon: 'bi pi-fw bi-house',
        command: () => {
          this.closeDrawer();
          this._routerSvc.navigateByUrl(this.url);
        },
        data: { breadcrumb: { skip: true } },
      },

      /**
       * -------------------------------------------------------------------------------
       *  council management menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'CMS_VIEW_FINANCIAL_YEAR',
          'CMS_VIEW_CONFIGURED_REVENUE_SOURCE',
          'CMS_VIEW_COUNCIL_SIGNATORY',
          'CMS_ADD_COUNCIL',
          'CMS_ADD_INSTITUTION',
          'CMS_VIEW_UNMAPPED_REVENUE_SOURCE',
          'CMS_VIEW_MAPPED_REVENUE_SOURCE',
          'ps_approving_other_source_rate',
          'ps_get_bank',
        ],
        label: 'Council Management',
        icon: 'pi pi-fw bi-building',
        items: [
          {
            permissions: this.allUserPermissions,
            label: 'Council Profile',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`/main/council-management`);
            },
          },
          {
            permissions: ['CMS_ADD_FINANCIAL_YEAR'],
            label: 'Manage Financial Year',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/manage-financial-year`
              );
            },
          },
          {
            permissions: ['ps_save_collection_area_revenue_sources_mapping'],
            label: 'Collection Area Mapping',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/create-collection-area`
              );
            },
          },
          {
            permissions: [
              'CMS_VIEW_CONFIGURED_REVENUE_SOURCE',
              'CMS_VIEW_COUNCIL_SIGNATORY',
            ],
            label: 'Revenue Configurations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/revenue-configuration`
              );
            },
          },
          {
            permissions: ['ps_approving_other_source_rate'],
            label: 'Review Revenue Configurations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/review-revenue-configuration`
              );
            },
          },

          {
            permissions: [
              'CMS_VIEW_UNMAPPED_REVENUE_SOURCE',
              'CMS_VIEW_MAPPED_REVENUE_SOURCE',
            ],
            label: 'Manage Revenue Sources',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/manage-revenue-sources`
              );
            },
          },
          {
            permissions: ['CMS_VIEW_LAW'],
            label: 'Upload Laws',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`/main/council-management/law-req`);
            },
          },
          {
            permissions: ['CMS_VIEW_LAW_AMENDMENT'],
            label: 'Law Amendments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/law-amendment`
              );
            },
          },
          {
            permissions: ['CMS_VIEW_COUNCIL_BY_LAW'],
            label: 'By Laws',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`/main/council-management/by-laws`);
            },
          },
          {
            permissions: ['CMS_VIEW_COUNCIL_BY_LAW_AMENDMENT'],
            label: 'By Law Amendments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/council-management/by-law-amendment`
              );
            },
          },
          {
            permissions: ['ps_get_bank', 'ps_import_revenue_sources'],
            label: 'Setup',
            items: [
              {
                permissions: ['ps_get_bank'],
                label: 'Register Banks',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/council-management/setup/register-banks`
                  );
                },
              },
              {
                permissions: ['ps_import_revenue_sources'],
                label: 'Import Revenue Sources',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/council-management/setup/import-revenue-sources`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  agent management menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'ps_get_agent',
          'FMS_VIEW_INTERNAL_ACCOUNT_FLOAT',
          'ps_create_contract_review',
          'BS_APPROVE_ADJUSTMENT_BILL_REQUEST',
          'ps_adjustment_request_review',
          'ps_get_collection_point',
        ],
        label: 'Agent Management',
        icon: 'bi pi-fw bi-people',
        items: [
          {
            permissions: ['ps_get_agent'],
            label: 'Register',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/agent-management`);
            },
          },

          {
            permissions: ['FMS_VIEW_INTERNAL_ACCOUNT_FLOAT'],
            label: 'Assign Floats',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/agent-management/assign-agent-float`
              );
            },
          },
          {
            permissions: ['ps_saving_pos_agent'],
            label: 'Assign POS',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/agent-management/assign-pos-to-agent`
              );
            },
          },
          {
            permissions: ['ps_create_contract_review'],
            label: 'Review Contracts',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/agent-management/contract-approval`
              );
            },
          },

          {
            permissions: ['ps_get_collection_point'],
            label: 'Collection Areas',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/agent-management/add-collection-area`
              );
            },
          },
        ],
      },
      /**
       * -------------------------------------------------------------------------------
       *  collector management menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: ['ps_get_agent'],
        label: 'Collector Management',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            permissions: ['ps_get_agent'],
            label: 'Register',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/collector-management`);
            },
          },
          {
            permissions: ['ps_get_agent'],
            label: 'Assign POS',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/collector-management/assign-pos-to-collector`
              );
            },
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  POS management menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'ps_get_pos_status_dashboard',
          'ps_view_pos_staging',
          'ps_saving_pos_staging',
          'ps_get_agent',
          'ps_get_pos_by_area_code',
          'ps_get_pos_with_reregister_attempt_by_area_code',
          'ps_create_change_status_request',
          'ps_save_pos_registration_request',
          'ps_view_pos_configuration_request_change',
          'ps_view_change_status_request',
          'ps_get_pos_pos_registration_request',
          'ps_create_change_status_review',
          'ps_save_pos_registration_approval',
          'ps_view_pos_status',
          'ps_get_pos_app_version',
          'ps_view_council_unbilled_transactions',
          'ps_approve_pos_configuration_request_change',
          'ps_save_pos_configuration_request_change',
          'FMS_UPLOAD_ENTITY_CERTIFICATE',
          'ps_create_adjustment_request',
        ],
        label: 'POS Management',
        icon: 'bi pi-fw bi-tablet',
        // command: () => {
        //   this.closeDrawer();
        //   this._routerSvc.navigateByUrl(this.url);
        // },
        items: [
          {
            permissions: ['ps_view_pos_staging', 'ps_saving_pos_staging'],
            label: 'Register',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-registration`
              );
            },
          },
          {
            permissions: ['ps_get_agent'],
            label: 'Assign Floats',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/assign-float-to-pos`
              );
            },
          },
          {
            permissions: [
              'ps_get_pos_by_area_code',
              'ps_get_pos_with_reregister_attempt_by_area_code',
              'ps_create_change_status_request',
              'ps_save_pos_registration_request',
            ],
            label: 'Status Change',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-status-change`
              );
            },
          },
          {
            permissions: ['ps_save_pos_configuration_request_change'],
            label: 'Change Offline Limits',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-offline-limits-change-request`
              );
            },
          },
          {
            permissions: ['ps_approve_pos_configuration_request_change'],
            label: 'Review Offline Limits',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-offline-limits-change-approval`
              );
            },
          },
          {
            permissions: [
              'ps_view_change_status_request',
              'ps_get_pos_pos_registration_request',
              'ps_create_change_status_review',
              'ps_save_pos_registration_approval',
            ],
            label: 'Review Status Change',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-status-change-approval`
              );
            },
          },
          {
            permissions: ['ps_view_council_unbilled_transactions'],
            label: 'Transactions',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-transaction`
              );
            },
          },
          {
            permissions: ['ps_create_adjustment_request'],
            label: 'Transaction Adjustments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                'main/agent-management/pos-transaction-adjustment'
              );
            },
          },
          {
            permissions: ['ps_get_adjustment_request_by_approval_status'],
            label: 'Review Transaction Adjustments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                'main/agent-management/transaction-adjustment-review'
              );
            },
          },
          {
            permissions: ['ps_view_transaction_by_pos_and_recon_status'],
            label: 'Reconciliations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/pos-management/pos-reconciliation`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/pos-management/report`);
            },
          },
          {
            permissions: [
              'ps_view_pos_status',
              'ps_get_pos_app_version',
              'FMS_UPLOAD_ENTITY_CERTIFICATE',
            ],
            label: 'Setup',
            items: [
              {
                permissions: [
                  'ps_view_pos_status',
                  'ps_saving_pos_status',
                  'ps_editing_pos_status',
                ],
                label: 'statuses',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/pos-management/pos-status-setup`
                  );
                },
              },
              {
                permissions: [
                  'ps_get_pos_app_version',
                  'ps_saving_pos_app_version',
                  'ps_edit_pos_app_version',
                ],
                label: 'Versions',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/pos-management/pos-version-setup`
                  );
                },
              },
              {
                permissions: ['FMS_UPLOAD_ENTITY_CERTIFICATE'],
                label: 'Register Banks',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/float-management/configure-bank`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  accounting menu section
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'BS_VIEW_BILL',
          'BS_VIEW_ADJUSTMENT_BILL_REQUEST',
          'BS_APPROVE_ADJUSTMENT_BILL_REQUEST',
          'BS_VIEW_ADJUSTMENT_REASON',
          'REPORT_VIEW_CASHBOOK_ACCOUNT',
        ],
        label: 'Accounting',
        icon: 'pi pi-book',
        items: [
          {
            permissions: ['BS_VIEW_ADJUSTABLE_BILL'],
            label: 'Reconciliation',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/accounting/reconciliation`);
            },
          },
          // {
          //   permissions: ['BS_VIEW_ADJUSTABLE_BILL'],
          //   label: 'GePG Reconciliation',
          //   command: () => {
          //     this.closeDrawer();
          //     this._routerSvc.navigateByUrl(
          //       `main/accounting/gepg-reconciliation`
          //     );
          //   },
          // },
          {
            permissions: ['BS_VIEW_BILL'],
            label: 'Bills',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/accounting/generic-bill`);
            },
          },
          {
            permissions: ['BS_APPROVE_ADJUSTMENT_BILL_REQUEST'],
            label: 'Review Bill Adjustments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/accounting/approve-bill-adjustment`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/accounting/report`);
            },
          },
          {
            permissions: ['BS_ADD_ADJUSTMENT_REASON'],
            label: 'Setup',
            items: [
              {
                permissions: ['BS_ADD_ADJUSTMENT_REASON'],
                label: 'Adjustment Reasons',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/accounting/adjustment-reason-setup`
                  );
                },
              },
            ],
          },
        ],
      },
      /**
       * -------------------------------------------------------------------------------
       *  user-management menu section
       * -------------------------------------------------------------------------------
       */
      {
        label: 'User Management',
        permissions: [
          'UAAS_UPDATE_PASSWORD_POLICY',
          'UAAS_CREATE_USER',
          'UAAS_UPDATE_USER',
          'UAAS_VIEW_USER',
          'UAAS_UPDATE_LOCKOUT_POLICY',
          'UAAS_UPDATE_ROLE',
          'UAAS_CREATE_GROUP',
          'UAAS_VIEW_ROLE',
          'UAAS_LIST_GROUP',
          'UAAS_UPDATE_GROUP',
          'UAAS_VIEW_GROUP',
          'UAAS_LIST_ROLE',
          'UAAS_CREATE_GENERIC_ROLE',
          'UAAS_REMOVE_ROLE_TO_USER',
          'UAAS_LIST_USER',
          'UAAS_CREATE_ROLE',
          'UAAS_ADD_ROLE_TO_USER',
        ],
        icon: 'pi pi-fw pi-users',
        items: [
          {
            permissions: [
              'UAAS_CREATE_USER',
              'UAAS_UPDATE_USER',
              'UAAS_VIEW_USER',
              'UAAS_CREATE_GROUP',
              'UAAS_LIST_GROUP',
              'UAAS_UPDATE_GROUP',
              'UAAS_VIEW_GROUP',
              'UAAS_REMOVE_ROLE_TO_USER',
              'UAAS_LIST_USER',
              'UAAS_ADD_ROLE_TO_USER',
            ],
            label: 'Users and Groups',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/user-management/users-and-groups`
              );
            },
          },
          {
            permissions: [
              'UAAS_UPDATE_ROLE',
              'UAAS_VIEW_ROLE',
              'UAAS_LIST_ROLE',
              'UAAS_CREATE_GENERIC_ROLE',
              'UAAS_CREATE_ROLE',
            ],
            label: 'Roles and Permissions',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/user-management/roles-and-permissions`
              );
            },
          },
          {
            permissions: [
              'UAAS_UPDATE_PASSWORD_POLICY',
              'UAAS_UPDATE_LOCKOUT_POLICY',
            ],
            label: 'Password & Lockout Policies',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/user-management/password-and-lockout-policy`
              );
            },
          },
          {
            permissions: ['UAAS_CREATE_NIN_TIN_COMPANY_CARETAKER'],
            label: 'Settings',
            icon: 'bi pi-fw bi-person',
            items: [
              // {
              //   permissions: this.allUserPermissions,
              //   label: 'Roles',
              //   command: () => {
              //     this.closeDrawer();
              //     this._routerSvc.navigateByUrl(
              //       `main/user-management/roles-settings`
              //     );
              //   },
              // },
              {
                permissions: ['UAAS_CREATE_NIN_TIN_COMPANY_CARETAKER'],
                label: 'Register Companies',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/user-management/register-company`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'Terms & Conditions',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/user-management/terms-condition`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  business license  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: this.allUserPermissions,
        label: 'Business License',
        command: () => {
          this.closeDrawer();
          this._routerSvc.navigateByUrl(`main/business-license`);
        },
        icon: 'pi pi-briefcase',
        items: [
          {
            permissions: this.allUserPermissions,
            label: 'Assessments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/business-license/assessment`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Transfer Requests',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/business-license/transfer-requests`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Termination Requests',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/business-license/termination-requests`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Issued Licenses',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/business-license/issued-licenses`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/business-license/business-license-report`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Setup',
            items: [
              {
                permissions: this.allUserPermissions,
                label: 'Business Categories',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/business-category`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'Business Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/business-type`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'License Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/license-rate`
                  );
                },
              },

              {
                permissions: this.allUserPermissions,
                label: 'Assessment Criteria',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/assessment-creterial`
                  );
                },
              },

              {
                permissions: this.allUserPermissions,
                label: 'Inspection Elements',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/inspection-elements`
                  );
                },
              },

              {
                permissions: this.allUserPermissions,
                label: 'Assessment Configurations',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/assessment-configuration`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'Attachment Configurations',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/attachment-configuration`
                  );
                },
              },

              {
                permissions: this.allUserPermissions,
                label: 'Transfer Fees',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/transfer-fee`
                  );
                },
              },

              {
                permissions: this.allUserPermissions,
                label: 'Penalty Fees',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/penalty-fee`
                  );
                },
              },
              {
                permissions: ['BL_CREATE_BUSINESS_TYPE'],
                label: 'Penalty Fee Setup',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/business-license/penalty-fee-setup`
                  );
                },
              },
            ],
          },
        ],
      },
      /**
       * -------------------------------------------------------------------------------
       *  Hotel Levy  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: ['SL_VIEW_LEVY_APPLICATION', 'SL_VIEW_LEVY_PENALT_FEE'],
        label: 'Hotel Levy',
        icon: 'pi pi-money-bill',
        items: [
          {
            permissions: ['SL_VIEW_LEVY_APPLICATION'],
            label: 'Hotel Levy ',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/hotel-leavy`);
            },
          },
          {
            permissions: ['SL_VIEW_LEVY_PENALT_FEE'],
            label: 'Setup',
            items: [
              {
                permissions: ['SL_VIEW_LEVY_PENALT_FEE'],
                label: 'Penalty Fees',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/hotel-leavy/penalty-fee`);
                },
              },
            ],
          },
        ],
      },

      /**
       * ------------------------------------------------------------------------------
       *  Solid Waste  navigation
       * ------------------------------------------------------------------------------
       */

      // {
      //   permissions: this.isProduction ? null : this.allUserPermissions,
      //   label: 'Solid Waste',
      //   icon: 'pi pi-trash',
      //   items: [
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Agent Management',
      //       command: () => {
      //         this.closeDrawer();
      //         this._routerSvc.navigateByUrl(`main/solid-waste`);
      //       },
      //     },
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Households',
      //       command: () => {
      //         this.closeDrawer();
      //         this._routerSvc.navigateByUrl(`main/solid-waste/household`);
      //       },
      //     },
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Setup',
      //       items: [
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Charge Rates',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(`main/solid-waste/charge-rate`);
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
      /**
       * -----------------------------------------------------------------------------
       *  House rent  navigation
       * -----------------------------------------------------------------------------
       */
      {
        permissions: [
          'hrms_view_projects',
          'hrms_view_rentable_units',
          'hrms_view_contracts',
          'hrms_view_contract_termination_request_reviews',
          'hrms_view_rentable_unit_categories',
          'hrms_view_structure_types',
        ],
        label: 'House Rent',
        icon: 'pi pi-fw bi-building',
        items: [
          {
            permissions: ['hrms_view_projects'],
            label: 'Register Projects',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/house-rent/register-project`
              );
            },
          },
          {
            permissions: ['hrms_view_rentable_units'],
            label: 'Review Applications',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/house-rent/review-application`
              );
            },
          },
          {
            permissions: ['hrms_view_contracts'],
            label: 'Register Contracts',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/house-rent/register-contract`
              );
            },
          },
          {
            permissions: ['hrms_view_contract_termination_request_reviews'],
            label: 'Contract Terminations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/house-rent/terminate-contract`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`/main/house-rent/report`);
            },
          },
          {
            permissions: [
              'hrms_view_rentable_unit_categories',
              'hrms_view_structure_types',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['hrms_view_rentable_unit_categories'],
                label: 'Unit Categories',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/house-rent/structure-category`
                  );
                },
              },
              {
                permissions: ['hrms_view_structure_types'],
                label: 'Structure Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/house-rent/structure-type`
                  );
                },
              },
            ],
          },
        ],
      },
      /**
       * -----------------------------------------------------------------------------
       *  Fishing license  navigation
       * -----------------------------------------------------------------------------
       */
      {
        permissions: this.isProduction ? null : this.allUserPermissions,
        label: 'Fishing License',
        icon: 'pi pi-briefcase',
        items: [
          {
            permissions: ['FS_CREATE_ASSESSMENT'],
            label: 'Assessments',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/fishing-license`);
            },
          },

          {
            permissions: [
              'FS_CREATE_CHARGE_RATE',
              'FS_SAVE_ASSESSMENT_CRITERIA',
              'FS_SAVE_INSPECTION_ELEMENT',
              'FS_CAN_SAVE_FISHING_SUB_CATEGORY',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['FS_CREATE_CHARGE_RATE'],
                label: 'Charge Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/fishing-license/charge-rate`
                  );
                },
              },
              {
                permissions: ['FS_SAVE_ASSESSMENT_CRITERIA'],
                label: 'Assessment Criteria',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/fishing-license/assessment-criteria`
                  );
                },
              },
              {
                permissions: ['FS_SAVE_INSPECTION_ELEMENT'],
                label: 'Inspection Items',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/fishing-license/inspection-criteria`
                  );
                },
              },
              {
                permissions: ['FS_CAN_SAVE_FISHING_SUB_CATEGORY'],
                label: 'License Sub Category',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/fishing-license/sub-category`
                  );
                },
              },
            ],
          },
          {
            permissions: this.allUserPermissions,
            label: 'Report',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/fishing-license/report`);
            },
          },
        ],
      },

      /**
       * -----------------------------------------------------------------------------
       *  Building Permit  navigation
       * -----------------------------------------------------------------------------
       */

      {
        permissions: [
          'BP_CREATE_CHARGE',
          'BP_CREATE_CRITERIA',
          'BP_CREATE_APPLICATION_TYPE',
          'BP_CREATE_USER_TYPE',
          'BP_CREATE_USER_TYPE_CONFIG',
          'BP_CREATE_PAYMENT_MODE',
          'BP_CREATE_INSPECTION_ELEMENT',
          'BP_CREATE_GFS',
          'BP_CREATE_DEPARTMENT',
          'BP_CREATE_CONFIG_TYPE',
          'BP_CREATE_CURRENCY',
          'BP_CREATE_BUILD_TYPE',
          'BP_CREATE_RATE_REVIEW',
          'BP_CREATE_RATE',
          'BP_CREATE_PERMIT',
          'BP_CREATE_CHARGE',
          'BP_CREATE_ASSESSMENT',
        ],
        // permissions: this.allUserPermissions,
        label: 'Building Permit',
        icon: 'bi bi-building',
        items: [
          {
            // permissions: ['BP_CREATE_ASSESSMENT'],
            permissions: this.allUserPermissions,
            label: 'Scrutinization',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/building-permit`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/building-permit/bp-reports`);
            },
          },
          {
            permissions: [
              'BP_CREATE_CHARGE',
              'BP_CREATE_CRITERIA',
              'BP_CREATE_APPLICATION_TYPE',
              'BP_CREATE_USER_TYPE',
              'BP_CREATE_USER_TYPE_CONFIG',
              'BP_CREATE_PAYMENT_MODE',
              'BP_CREATE_INSPECTION_ELEMENT',
              'BP_CREATE_GFS',
              'BP_CREATE_DEPARTMENT',
              'BP_CREATE_CONFIG_TYPE',
              'BP_CREATE_CURRENCY',
              'BP_CREATE_BUILD_TYPE',
              'BP_CREATE_RATE_REVIEW',
              'BP_CREATE_RATE',
              'BP_CREATE_PERMIT',
              'BP_CREATE_CHARGE',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['BP_CREATE_APPLICATION_TYPE'],
                // permissions: this.allUserPermissions,
                label: 'Application Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/application-type`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_BUILD_TYPE'],
                // permissions: this.allUserPermissions,
                label: 'Building Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/build-type`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_DEPARTMENT'],
                // permissions: this.allUserPermissions,
                label: 'Assessing Departments',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/department`
                  );
                },
              },
              {
                // permissions: ['BP_CREATE_DEPARTMENT'],
                permissions: this.allUserPermissions,
                label: 'Lot use',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/building-permit/lot-use`);
                },
              },
              {
                permissions: ['BP_CREATE_USER_TYPE'],
                // permissions: this.allUserPermissions,
                label: 'Attachment Configurations',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/attachment-configuration`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_CONFIG_TYPE'],
                // permissions: this.allUserPermissions,
                label: 'Assessment Configurations',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/assessment-configuration`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_CRITERIA'],
                // permissions: this.allUserPermissions,
                label: 'Assessment Criteria',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/assessment-criteria`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_USER_TYPE_CONFIG'],
                // permissions: this.allUserPermissions,
                label: 'User Type Departments',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/user-type`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_USER_TYPE_CONFIG'],
                // permissions: this.allUserPermissions,
                label: 'Review Levels',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/user-type-assessment-configuration`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_INSPECTION_ELEMENT'],
                // permissions: this.allUserPermissions,
                label: 'Inspection Elements',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/inspection-element`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_GFS'],
                label: 'GFS Code',
                // permissions: this.allUserPermissions,
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/gfs-code`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_CURRENCY'],
                // permissions: this.allUserPermissions,
                label: 'Currency Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/currency`
                  );
                },
              },
              {
                permissions: ['BP_CREATE_CURRENCY'],
                // permissions: this.allUserPermissions,
                label: 'Payment Modes',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/payment-mode`
                  );
                },
              },
              {
                // permissions: ['BP_CREATE_RATE'],
                permissions: this.allUserPermissions,
                label: 'Charge Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/charge-rate`
                  );
                },
              },
              {
                // permissions: ['BP_CREATE_RATE'],
                permissions: this.allUserPermissions,
                label: 'Reports setup',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/building-permit/report-setup`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  Billboard  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: this.allUserPermissions,
        label: 'Billboard',
        icon: 'pi pi-file',
        command: () => {
          this._routerSvc.navigateByUrl(`main/billboard`);
        },
        items: [
          {
            permissions: [
              'BB_VIEW_ASSESSMENT',
              'BB_CREATE_ASSESSMENT',
              'BB_UPDATE_ASSESSMENT',
              'BB_DELETE_ASSESSMENT',
            ],
            // permissions: this.allUserPermissions,
            label: 'Applications',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/billboard/billboard-applications`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Billboard Requests',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/billboard/billboard-requests`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/billboard/billboard-reports`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Setup',
            items: [
              {
                permissions: [
                  'BB_VIEW_ADVERTISEMENT_CATEGORY',
                  'BB_CREATE_ADVERTISEMENT_CATEGORY',
                  'BB_UPDATE_ADVERTISEMENT_CATEGORY',
                  'BB_DELETE_ADVERTISEMENT_CATEGORY',
                ],
                // permissions: this.allUserPermissions,
                label: 'Billboard category',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/billboard-category`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_ADVERTISEMENT_TYPE',
                  'BB_CREATE_ADVERTISEMENT_TYPE',
                  'BB_UPDATE_ADVERTISEMENT_TYPE',
                  'BB_DELETE_ADVERTISEMENT_TYPE',
                ],
                // permissions: this.allUserPermissions,
                label: 'Advertisement type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/advertisement-type`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_APPLICATION_TYPE',
                  'BB_CREATE_APPLICATION_TYPE',
                  'BB_UPDATE_APPLICATION_TYPE',
                  'BB_DELETE_APPLICATION_TYPE',
                ],
                // permissions: this.allUserPermissions,
                label: 'Application type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/application-type`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_APPLICATION_STATUS_TYPE',
                  'BB_CREATE_APPLICATION_STATUS_TYPE',
                  'BB_UPDATE_APPLICATION_STATUS_TYPE',
                  'BB_DELETE_APPLICATION_STATUS_TYPE',
                ],
                // permissions: this.allUserPermissions,
                label: 'Application status type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/application-status-type`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_BILLBOARD_UOM',
                  'BB_CREATE_BILLBOARD_UOM',
                  'BB_UPDATE_BILLBOARD_UOM',
                  'BB_DELETE_BILLBOARD_UOM',
                ],
                // permissions: this.allUserPermissions,
                label: 'Unit of measure',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/unit-of-measure`
                  );
                },
              },
              {
                permissions: [
                  'BB_CREATE_BILLBOARD_RATE',
                  'BB_CREATE_BILLBOARD_RATE_REVIEW',
                ],
                // permissions: this.allUserPermissions,
                label: 'Billboard rate',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/billboard-rate`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_BILLBOARD_DEPARTMENT',
                  'BB_CREATE_BILLBOARD_DEPARTMENT',
                  'BB_UPDATE_BILLBOARD_DEPARTMENT',
                  'BB_DELETE_BILLBOARD_DEPARTMENT',
                ],
                // permissions: this.allUserPermissions,
                label: 'Department',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/billboard/department`);
                },
              },
              {
                permissions: [
                  'BB_VIEW_USER_TYPE_CONFIG',
                  'BB_CREATE_USER_TYPE_CONFIG',
                  'BB_UPDATE_USER_TYPE_CONFIG',
                  'BB_DELETE_USER_TYPE_CONFIG',
                ],
                // permissions: this.allUserPermissions,
                label: 'User Type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/billboard/user-type`);
                },
              },
              {
                permissions: [
                  'BB_VIEW_USER_TYPE_CONFIG',
                  'BB_CREATE_USER_TYPE_CONFIG',
                  'BB_UPDATE_USER_TYPE_CONFIG',
                  'BB_DELETE_USER_TYPE_CONFIG',
                ],
                // permissions: this.allUserPermissions,
                label: 'Approval Levels',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/user-type-assessment-configuration`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_GFS',
                  'BB_CREATE_GFS',
                  'BB_UPDATE_GFS',
                  'BB_DELETE_GFS',
                ],
                label: 'GFS Code',
                // permissions: this.allUserPermissions,
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/billboard/gfs-code`);
                },
              },
              {
                permissions: [
                  'BB_VIEW_INSPECTION_ELEMENT',
                  'BB_CREATE_INSPECTION_ELEMENT',
                  'BB_UPDATE_INSPECTION_ELEMENT',
                  'BB_DELETE_INSPECTION_ELEMENT',
                ],
                // permissions: this.allUserPermissions,
                label: 'Inspection Elements',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/inspection-element`
                  );
                },
              },
              {
                permissions: [
                  'BB_VIEW_ATTACHMENT_TYPE',
                  'BB_CREATE_ATTACHMENT_TYPE',
                  'BB_UPDATE_ATTACHMENT_TYPE',
                  'BB_DELETE_ATTACHMENT_TYPE',
                ],
                // permissions: this.allUserPermissions,
                label: 'Attachment configuration',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/billboard/attachment-configuration`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'Report Setup',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/billboard/report-setup`);
                },
              },
            ],
          },
        ],
      },

      /**
       * ----------------------------------------------------------------------------
       *  Bus stand  navigation
       * ----------------------------------------------------------------------------
       */
      // {
      //   permissions: this.isProduction ? null : this.allUserPermissions,
      //   label: 'Bus Stand',
      //   icon: 'pi pi-car',
      //   items: [
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Register',
      //       command: () => {
      //         this.closeDrawer();
      //         this._routerSvc.navigateByUrl(`main/bus-stand`);
      //       },
      //     },
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Setup',
      //       items: [
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Companies',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(`main/bus-stand/company`);
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Vehicle categories',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/bus-stand/vehicle-category`
      //             );
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Vehicles',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(`main/bus-stand/vehicle`);
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Vehicle rates',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(`main/bus-stand/vehicle-rate`);
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Register Bus Stand',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/bus-stand/bus-stand-registration`
      //             );
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Bus stand gates',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/bus-stand/bus-stand-gate`
      //             );
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
      /**
       * ----------------------------------------------------------------------------
       *  service levy  navigation
       * ----------------------------------------------------------------------------
       */
      {
        permissions: [
          'SL_VIEW_SUBMITTED_TURNOVER',
          'SL_VIEW_TURNOVER_RECONCILIATION_REQUEST',
          'SL_VIEW_TURNOVER_RECONCILIATION_REQUEST',
          'SL_VIEW_LEVY_APPLICATION',
          'SL_VIEW_LEVY_CATEGORIES',
          'SL_VIEW_CONFIGURE_REVENUE_SOURCE',
          'SL_VIEW_LEVY_ATTACHMENT_TYPE',
        ],
        label: 'Service Levy',
        icon: 'pi pi-percentage',
        items: [
          {
            permissions: ['SL_VIEW_SUBMITTED_TURNOVER'],
            label: 'Turnovers',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/service-levy`);
            },
          },
          {
            permissions: ['SL_VIEW_TURNOVER_RECONCILIATION_REQUEST'],
            label: 'Reconciliations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/service-levy/reconciliation`);
            },
          },
          {
            permissions: ['SL_VIEW_LEVY_APPLICATION'],
            label: 'Categorizations',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/service-levy/levy-categorization`
              );
            },
          },
          {
            permissions: [
              'SL_VIEW_LEVY_CATEGORIES',
              'SL_VIEW_CONFIGURE_REVENUE_SOURCE',
              'SL_VIEW_LEVY_ATTACHMENT_TYPE',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['SL_VIEW_LEVY_CATEGORIES'],
                label: 'Levy Categories',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/service-levy/levy-categories`
                  );
                },
              },
              {
                permissions: ['SL_VIEW_LEVY_CATEGORIES'],
                label: 'Category Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/service-levy/category-rates`
                  );
                },
              },
              {
                permissions: ['SL_VIEW_CONFIGURE_REVENUE_SOURCE'],
                label: 'GFS Code',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/service-levy/service-levy-gfs-code`
                  );
                },
              },
              {
                permissions: ['SL_VIEW_LEVY_ATTACHMENT_TYPE'],
                label: 'Attachment Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/service-levy/attachment-type`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * --------------------------------------------------------------------------
       *  Land Sales
       * --------------------------------------------------------------------------
       */

      {
        permissions: [
          'LSS_VIEW_LAND_PROJECT',
          'LSS_VIEW_LAND_PROJECT_EXTENSION',
          'LSS_VIEW_LAND_PLOT_APPLICATION',
        ],
        label: 'Land Sales',
        icon: 'bi bi-wallet',
        items: [
          {
            permissions: ['LSS_VIEW_LAND_PROJECT'],
            label: 'Register Project',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/projects`);
            },
          },
          {
            permissions: ['LSS_VIEW_LAND_PROJECT_EXTENSION'],
            label: 'Project Extension Request',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/project-extension`);
            },
          },
          {
            permissions: ['LSS_RETURN_LAND_PROJECT_REQUEST','LSS_RETURN_LAND_PROJECT_REVIEW'],
            label: 'Plots Return Request',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/project-plot-return`);
            },
          },
          {
            permissions: ['LSS_VIEW_LAND_PLOT_APPLICATION'],
            label: 'Applications',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/application`);
            },
          },
          {
            permissions: [
              'LSS_VIEW_LAND_PROJECT',
              'LSS_VIEW_CONFIGURE_REVENUE',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['LSS_VIEW_CONFIGURE_REVENUE'],
                label: 'GFS Code Mapping',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/setup/gfs-code-mapping`);
                },
              },
            ],
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/reports`);
            },
          },
        ],
      },

      /**
       * --------------------------------------------------------------------------
       *  Land Rent
       * --------------------------------------------------------------------------
       */

      // {
      //   permissions: this.allUserPermissions,
      //   label: 'Land Rent(s)',
      //   icon: 'bi bi-pin-map-fill',
      //   command: () => {
      //     this._routerSvc.navigateByUrl(`main/land-rent`);
      //   },
      //   items: [
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Plots & Charges',
      //       command: () => {
      //         this.closeDrawer();
      //         this._routerSvc.navigateByUrl(`main/land-rent/plots`);
      //       },
      //     },
      //     {
      //       permissions: this.allUserPermissions,
      //       label: 'Setup',
      //       items: [
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Collection Gfs Codes',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/land-rent/collection-gfsCode`
      //             );
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Collection Partner',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/land-rent/collection-partner`
      //             );
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Payment Schedule',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(
      //               `main/land-rent/payment-schedule`
      //             );
      //           },
      //         },
      //         {
      //           permissions: this.allUserPermissions,
      //           label: 'Location',
      //           command: () => {
      //             this.closeDrawer();
      //             this._routerSvc.navigateByUrl(`main/land-rent/location`);
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },

      /**
       * -------------------------------------------------------------------------------
       *  Auctioning  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: ['AS_VIEW_AUCTION'],
        label: 'Auctioning',
        icon: 'pi pi-volume-up',
        items: [
          {
            permissions: this.allUserPermissions,
            label: 'Dashboard',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/auctioning`);
            },
          },
          {
            permissions: ['AS_VIEW_AUCTION'],
            label: 'Register Auctions',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/auctioning/register-auction`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Report',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/auctioning/report`);
            },
          },
          {
            permissions: ['AS_SAVE_AUCTION_CONDITION', 'AS_SAVING_ITEM_GROUP'],
            label: 'Setup',
            items: [
              {
                permissions: this.allUserPermissions,
                label: 'Auction Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/auctioning/auction-type`);
                },
              },
              {
                permissions: ['AS_SAVING_ITEM_GROUP'],
                label: 'Item Groups',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/auctioning/item-group`);
                },
              },
              {
                permissions: ['AS_SAVE_AUCTION_CONDITION'],
                label: 'Item Conditions',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/auctioning/item-condition`
                  );
                },
              },
              // {
              //   permissions: ['AS_SAVE_AUCTION_REMARK'],
              //   label: 'Auction Remarks',
              //   command: () => {
              //     this.closeDrawer();
              //     this._routerSvc.navigateByUrl(
              //       `main/auctioning/auction-remark`
              //     );
              //   },
              // },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  Parking  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'PKS_CAN_VIEW_AGENT_COMMENT',
          'PKS_CAN_SAVE_REVIEW_APPLICATION',
          'PKS_CAN_VIEW_FAILED_TRA_REQUEST',
          'PKS_CAN_SAVE_ADJUSTMENT_REQUEST',
          'PKS_CAN_SAVE_VALID_PARKING_HOUR',
          'PKS_CAN_SAVE_EXCEPTED_VALID_PARKING_HOUR',
          'PKS_CAN_SAVE_EXCEPTED_PARKING_DAY',
          'PKS_CAN_SAVE_NOTIFICATION_THRESHOLD',
          'PKS_CAN_SAVE_PARKING_SERVICE',
          'PKS_CAN_SAVE_PAYMENT_PLAN',
          'PKS_CAN_SAVE_COUNCIL_PARKING_DAY',
          'PKS_CAN_SAVE_PARKING_RATE',
          'PKS_CAN_SAVE_ASSESSMENT_CRITERIA',
          'PKS_CAN_SAVE_DEPARTMENT_REVIEW_LEVEL',
          'PKS_CAN_VIEW_PARKING_RATE_COMPLETENESS',
          'PKS_CAN_SAVE_VEHICLE_CATEGORY',
          'PKS_CAN_SAVE_REASON',
          'PKS_CAN_VIEW_ACTIVATED_COUNCIL',
        ],
        label: 'Parking',
        icon: 'pi pi-car',
        command: () => {
          this._routerSvc.navigateByUrl(`main/parking`);
        },
        items: [
          {
            permissions: ['PKS_CAN_VIEW_AGENT_COMMENT'],
            label: 'Normal Parking',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/parking/parking-scans`);
            },
          },
          {
            permissions: ['PKS_CAN_SAVE_REVIEW_APPLICATION'],
            label: 'Applications',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/parking/parking-applications`
              );
            },
          },
          {
            permissions: ['PKS_CAN_VIEW_FAILED_TRA_REQUEST'],
            label: 'Failed TRA',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/parking/vehicle-details`);
            },
          },
          {
            permissions: ['PKS_CAN_SAVE_ADJUSTMENT_REQUEST'],
            label: 'Customer Complaints',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/parking/customer-complaints`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Bad Scans',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/parking/bad-scans`);
            },
          },
          {
            permissions: [
              'PKS_CAN_SAVE_VALID_PARKING_HOUR',
              'PKS_CAN_SAVE_EXCEPTED_VALID_PARKING_HOUR',
              'PKS_CAN_SAVE_EXCEPTED_PARKING_DAY',
              'PKS_CAN_SAVE_NOTIFICATION_THRESHOLD',
              'PKS_CAN_SAVE_PARKING_SERVICE',
              'PKS_CAN_SAVE_PAYMENT_PLAN',
              'PKS_CAN_SAVE_COUNCIL_PARKING_DAY',
              'PKS_CAN_SAVE_PARKING_RATE',
              'PKS_CAN_SAVE_ASSESSMENT_CRITERIA',
              'PKS_CAN_SAVE_DEPARTMENT_REVIEW_LEVEL',
              'PKS_CAN_VIEW_PARKING_RATE_COMPLETENESS',
              'PKS_CAN_SAVE_VEHICLE_CATEGORY',
              'PKS_CAN_SAVE_REASON',
              'PKS_CAN_VIEW_ACTIVATED_COUNCIL',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['PKS_CAN_VIEW_ACTIVATED_COUNCIL'],
                label: 'Parking Councils',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/parking/council`);
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_VALID_PARKING_HOUR'],
                label: 'Parking Hours',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/parking/parking-hour`);
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_EXCEPTED_VALID_PARKING_HOUR'],
                label: 'Exempted Valid Parking Hours',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/excepted-valid-parking-hour`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_EXCEPTED_PARKING_DAY'],
                label: 'Public Holidays',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/excepted-parking-days`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_NOTIFICATION_THRESHOLD'],
                label: 'Notification Thresholds',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/notification-threshold`
                  );
                },
              },

              {
                permissions: ['PKS_CAN_SAVE_PARKING_SERVICE'],
                label: 'Parking Charges',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/parking/parking-service`);
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_PAYMENT_PLAN'],
                label: 'Payment Plans',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/parking/payment-plan`);
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_COUNCIL_PARKING_DAY'],
                label: 'Exempted Public Holidays',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/council-parking-day`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_PARKING_RATE'],
                label: 'Parking rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/parking/parking-rate`);
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_ASSESSMENT_CRITERIA'],
                label: 'Assessment Criteria',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/assessment-criteria`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_DEPARTMENT_REVIEW_LEVEL'],
                label: 'Department for Assessment',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/department-level`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_VIEW_PARKING_RATE_COMPLETENESS'],
                label: 'Setup completeness',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/setup-completeness`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_VEHICLE_CATEGORY'],
                label: 'Vehicle Category',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/vehicle-category`
                  );
                },
              },
              {
                permissions: ['PKS_CAN_SAVE_REASON'],
                label: 'Complaints Reason',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/parking/complaint-reason`
                  );
                },
              },
            ],
          },
          {
            permissions: this.allUserPermissions,
            label: 'Report',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/parking/report`);
            },
          },
        ],
      },
      /**
       * -------------------------------------------------------------------------------
       *  Livestock Movement Permit  navigation
       * -------------------------------------------------------------------------------
       */
      {
        permissions: [
          'exs_view_livestock_officers',
          'exs_view_agent_assigned_sources',
          'exs_view_animal_permit_adjustment_requests',
          'exs_view_animal_species',
          'exs_view_means_of_transport',
          'exs_view_livestock_officer_designations',
          'exs_save_animal_permit_adjustment_reviews',
          'exs_view_animal_permit_rates',
          'exs_save_animal_permit_adjustment_reviews',
        ],
        label: 'Livestock Market',
        icon: 'pi pi-copy',
        // command: () => {
        //   this._routerSvc.navigateByUrl(`main/animal-movement`);
        // },
        items: [
          {
            permissions: ['exs_view_livestock_officers'],
            label: 'Livestock Officers',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/animal-movement/livestock-officer`
              );
            },
          },
          {
            permissions: ['exs_view_agent_assigned_sources'],
            label: 'Agent Sources',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/animal-movement/external-agent-source`
              );
            },
          },
          {
            permissions: ['exs_view_animal_permit_adjustment_requests'],
            label: 'Livestock Permits',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/animal-movement/external-source-adjustment`
              );
            },
          },
          {
            permissions: ['exs_save_animal_permit_adjustment_reviews'],
            label: 'Adjustment Reviews',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/animal-movement/external-source-adjustment-review`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Report',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/animal-movement/external-source-report`
              );
            },
          },
          {
            permissions: [
              'exs_view_animal_species',
              'exs_view_means_of_transport',
              'exs_view_livestock_officer_designations',
              'exs_view_animal_permit_rates',
            ],
            label: 'Setup',
            items: [
              {
                permissions: ['exs_view_animal_species'],
                label: 'Animal Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/animal-movement/animal-type`
                  );
                },
              },
              {
                permissions: ['exs_view_means_of_transport'],
                label: 'Transport Types',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/animal-movement/transport-type`
                  );
                },
              },
              {
                permissions: ['exs_view_livestock_officer_designations'],
                label: 'Livestock Officer Designations',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/animal-movement/livestock-officer-designation`
                  );
                },
              },
              {
                permissions: ['exs_view_animal_permit_rates'],
                label: 'Livestock Permit Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/animal-movement/livestock-permit-rate`
                  );
                },
              },
            ],
          },
        ],
      },

      /**
       * -------------------------------------------------------------------------------
       *  Property Tax
       * -------------------------------------------------------------------------------
       *
       *  */
      {
        permissions: [
          'PTS_GET_BUILDING',
          'PTS_CAN_IMPORT_NEW_METER',
          'PTS_CAN_IMPORT_DEDUCTION',
        ],
        label: 'Property Tax',
        icon: 'pi pi-fw bi-building',
        command: () => {
          this._routerSvc.navigateByUrl(`main/property-tax`);
        },

        items: [
          {
            permissions: ['PTS_GET_BUILDING'],
            label: 'Buildings',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/property-tax/registered-building`
              );
            },
          },
          {
            permissions: ['PTS_GET_BUILDING'],
            label: 'Building Requests',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/property-tax/building`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Exempted Buildings',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/property-tax/exempted-building`
              );
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Building Status Change',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `main/property-tax/building-status-change`
              );
            },
          },
          {
            permissions: [
              'PTS_CAN_IMPORT_NEW_METER',
              'PTS_CAN_IMPORT_DEDUCTION',
            ],
            label: 'Import Data',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/property-tax/import-data`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Data Collector',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/property-tax/data-collector`);
            },
          },
          {
            permissions: this.allUserPermissions,
            label: 'Reports',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(`main/property-tax/report`);
            },
          },
          {
            // permissions: [
            //   'PTS_CREATE_USE_TYPE',
            //   'PTS_CREATE_PAYMENT_MODE',
            //   'PTS_CREATE_BUILDING_TYPE',
            //   'PTS_CREATE_BUILDING_STRUCTURE',
            //   'PTS_CREATE_ATTACHMENT_TYPE',
            //   'PTS_CREATE_CHARGE_RATE',
            //   'PTS_SAVE_GFS_CODE',
            //   'PTS_CREATE_EXCEPTION_REASON',
            // ],
            permissions: this.allUserPermissions,
            label: 'Setup',
            items: [
              {
                permissions: ['PTS_CREATE_USE_TYPE'],
                label: 'Building Use Type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/property-tax/use-type`);
                },
              },
              {
                permissions: ['PTS_CREATE_PAYMENT_MODE'],
                label: 'Payment Mode',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/payment-mode`
                  );
                },
              },
              {
                permissions: ['PTS_CREATE_BUILDING_TYPE'],
                label: 'Building Type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/building-type`
                  );
                },
              },
              {
                permissions: ['PTS_CREATE_BUILDING_STRUCTURE'],
                label: 'Building Structure',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/building-structure`
                  );
                },
              },
              {
                permissions: ['PTS_CREATE_ATTACHMENT_TYPE'],
                label: 'Attachment Type',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/attachment-type`
                  );
                },
              },
              {
                permissions: ['PTS_CREATE_CHARGE_RATE'],
                label: 'Charge Rates',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/charge-rate`
                  );
                },
              },
              {
                permissions: ['PTS_SAVE_GFS_CODE'],
                label: 'Setup GfsCodes',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(`main/property-tax/gfscodes`);
                },
              },
              {
                permissions: ['PTS_CREATE_EXCEPTION_REASON'],
                label: 'Exemption Reason',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/exemption-reason`
                  );
                },
              },
              {
                permissions: this.allUserPermissions,
                label: 'Revenue Distribution',
                command: () => {
                  this.closeDrawer();
                  this._routerSvc.navigateByUrl(
                    `main/property-tax/allocation-setup`
                  );
                },
              },
            ],
          },
        ],
      },
    ];
  }

  /**
   * ------------------------------------------------------------------------------------
   * @returns
   * -----------------------------------------------------------------------------------
   */
  public getSettingsNavLinks() {
    return [
      {
        label: 'Council Setup',
        icon: 'bi pi-fw  bi-gear',
        command: () => {
          this.closeDrawer();
          this._routerSvc.navigateByUrl(
            `/main/system-configuration/council-setup`
          );
        },
      },
      {
        label: 'Revenue Sources',
        icon: 'pi pi-fw pi-briefcase',
        items: [
          {
            label: 'Property Tax Setup',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/system-configuration/property-tax-setup`
              );
            },
          },
          {
            label: 'Billboard Setup',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/system-configuration/billboard-setup`
              );
            },
          },
        ],
      },

      {
        label: 'Notification Setup',
        icon: 'pi pi-fw bi-bell',
        items: [
          {
            label: 'Templates',
            command: () => {
              this.closeDrawer();
              this._routerSvc.navigateByUrl(
                `/main/system-configuration/templates`
              );
            },
          },
        ],
      },

      {
        label: 'System Settings',
        icon: 'bi pi-fw  bi-gear',
        command: () => {
          this.closeDrawer();
          this._routerSvc.navigateByUrl(
            `/main/system-configuration/system-settings`
          );
        },
      },
    ];
  }

  getToolbarMenu() {
    return [
      {
        label: 'Account',
        items: [
          {
            label: 'My Profile',
            icon: 'pi pi-user',
            command: () => {
              this.update();
            },
          },
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            command: () => {},
          },
        ],
      },
      {
        label: 'actions',
        items: [
          {
            label: 'Sign Out',
            icon: 'pi pi-external-link',
            command: () => {
              this.logout();
            },
          },
          {
            label: 'Reset Password',
            icon: 'pi pi-upload',
            command: () => {},
          },
        ],
      },
    ];
  }

  update() {
    this._toastSvc.success('Data Updated');
  }

  logout() {
    this._authService.logout().subscribe(
      (res: any) => {
        //console.log("logout res: ", res);
      },
      (err: HttpErrorResponse) => {
        // console.log(`Got error: ${err}`)
      }
    );
  }
}
