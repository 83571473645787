<ngb-carousel *ngIf="slides">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="slides[0]" alt="Random first slide" />
    </div>
    <div class="carousel-caption">
      <h3>TAUSI</h3>
      <p>Mfumo wa Ukusanyaji wa Mapato Serikali za Mitaa.</p>
    </div>
  </ng-template>
</ngb-carousel>
