import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-shared-footer',
  templateUrl: './shared-footer.component.html',
  styleUrls: ['./shared-footer.component.scss']
})
export class SharedFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
