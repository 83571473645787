
export class AdminHierarchy {
  constructor(
    public id?: string,
    public name?: string,
    public areaTypeId?: number,
    public parent_id?: string,
    public parent?: AdminHierarchy,
    public children?: AdminHierarchy[]
  ) {}
}
