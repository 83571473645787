import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  UserSwitchingAccount,
  UserSwitchingAccountArray,
} from 'src/app/shared/models/auth.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { PermissionFilteringService } from 'src/app/shared/services/permission-filtering.service';
import { UserStorageService } from 'src/app/shared/services/user-storage.service';
import { UserUaaService } from 'src/app/user-management/services/user-uaa.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../../land-sales/service/side-nav-service';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { MenuItem } from 'primeng/api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { DialogService } from 'primeng/dynamicdialog';
import { WelcomeNotificationComponent } from 'src/app/shared/components/welcome-notification/welcome-notification.component';
import { UpdateNotificationNumberComponent } from 'src/app/shared/components/update-notification-number/update-notification-number.component';
import { InteractionService } from 'src/app/shared/services/interaction.service';

const dialogStyle = {
  'background-color': '#f6f6f6',
  padding: '0px',
  'max-height': '900px',
  overflow: 'none',
};

@Component({
  selector: 'tausi-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit {
  @ViewChild('appDrawer') appDrawer?: ElementRef;
  @ViewChild('filter') public leftSideNav!: MatSidenav;
  @ViewChild('info') public rightSideNav!: MatSidenav;

  public isOpen: boolean;
  public items!: any[];
  public itemsToShowUser!: any[];
  public user!: any;
  public avatar!: any;

  public displaySwitchingAccount: boolean = false;
  public currentUserAccount!: any;
  public currentUser!: any;
  public listOfMyAccounts!: any;
  public selectedSwitchingAccount!: any;
  public providedPassword!: string;
  public fullName!: string;
  public allUserPermissions!: string[];
  public currentYear: any;
  public isMapView!: boolean;

  public isLoading!: boolean;
  public message!: string;
  public status: OnlineStatusType = this.onlineStatusService.getStatus();
  public menuItems!: MenuItem[];
  constructor(
    private _navigationSvc: NavigationService,
    private _authService: AuthService,
    private _userUaaService: UserUaaService,
    private _userStorageService: UserStorageService,
    private _router: Router,
    private _permissionFilteringService: PermissionFilteringService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private _messageSvc: MessageService,
    private router: Router,
    private sidenavService: SidenavService,
    private _confirmationSvc: ConfirmationService,
    private onlineStatusService: OnlineStatusService,
    private _toastSvc: ToastService,
    private _dialogSvc: DialogService,
    private _interactionSvc: InteractionService
  ) {
    this.isOpen = false;
    this.currentYear = new Date().getFullYear();
    this.isMapView = false;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.detectChange({ has_map: false });
      }

      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('map') !== -1) {
          this.isMapView = true;
        } else {
          this.isMapView = false;
        }
      }
    });
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.status = status;
    });
    this.initializeMenu();
    setTimeout(() => {
      this.loadUserInfo();
    }, 1000);
  }

  ngOnInit() {
    setTimeout(() => {
      this.showNotification();
      this.avatar = `data:image/png;base64,${this.user?.avatar}`;
    }, 0);
    this.displaySwitchingAccount = false;
  }

  transform(base64Image: any) {
    let newbase64Image =
      'data:image/png;base64,' +
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Image) as any)
        .changingThisBreaksApplicationSecurity;

    return this.sanitizer.bypassSecurityTrustResourceUrl(newbase64Image);
  }

  showNotification() {
    this._messageSvc.add({
      severity: 'success',
      summary: 'Service Message',
      detail: 'Via MessageService',
    });
  }

  ngAfterViewInit() {
    this._navigationSvc.appDrawer = this.appDrawer;
    this._navigationSvc.isOpen$.subscribe((status) => (this.isOpen = status));
    this._authService.isEverythingLoaded$.subscribe((flag: any) => {
      if (flag) {
        this._authService.isLoggedIn$.subscribe(($Flag: boolean) => {
          if ($Flag) {
            this.items = this._navigationSvc.getNavLinks();

            if (this._userStorageService.getPermissions()) {
              this.items = this._permissionFilteringService.filterByNodes(
                this.items
              );
            }
          }
        });
      }
    });

    this.cdr.detectChanges();
    this.sidenavService.setLeftSidenav(this.leftSideNav);
    this.sidenavService.setRightSidenav(this.rightSideNav);
  }

  loadUserInfo() {
    this.itemsToShowUser = [];
    this.user = this._userStorageService.getUserDetails();
    this.currentUser = this.user?.nindetail;

    this.fullName = `${
      this.currentUser?.firstName
    } ${this.currentUser?.middleName.charAt(0)}. ${this.currentUser?.surname}`;

    // other
    this._authService.activeShwitchedAccount$.subscribe(
      (account: UserSwitchingAccount) => {
        this.currentUserAccount = account;
        this.selectedSwitchingAccount = account;
      }
    );
    this._authService.listOfSwitchingAccounts$.subscribe(
      (accounts: UserSwitchingAccountArray) => {}
    );
    if (this.user) {
      if (!this.user.accountNotificationVerified) {
        this.showUpdatePhoneNumberNotification();
      }
    }
  }

  public toggle() {
    if (this.isOpen) {
      this._navigationSvc.closeDrawer();
    } else {
      this._navigationSvc.openDrawer();
    }
  }

  logout() {
    this._authService.logout().subscribe(
      (res: any) => {},
      (err: HttpErrorResponse) => {
        this._authService.clearAuthStorage();
        this._router.navigateByUrl('/signin');
      }
    );
  }

  reloadCurrentRoute() {
    window.location.reload();
  }

  showSwitchingAccountDialog() {
    this._navigationSvc.closeDrawer();
    this.listOfMyAccounts = this._userStorageService.getSwitchingAccounts();

    if (
      typeof this.listOfMyAccounts !== 'undefined' &&
      this.listOfMyAccounts != null &&
      this.listOfMyAccounts.length > 0
    ) {
      let listOfMyAccountsWithGoodDisplayName = this.listOfMyAccounts.map(
        function (obj: any, index: any) {
          return Object.assign({}, obj, {
            displayName: obj.name + ' - ' + obj.areaType.areaLevel.name,
          });
        }
      );

      this.listOfMyAccounts = listOfMyAccountsWithGoodDisplayName;
      this.displaySwitchingAccount = true;
    } else {
      this.displaySwitchingAccount = false;
    }
  }

  switchingAccount() {
    const loginPayload = {
      username: this._userStorageService.getUserDetails()?.username,
      administrativeAreaId: this.selectedSwitchingAccount?.id,
      password: this.providedPassword,
    };

    this._userUaaService.switchAccount(loginPayload).subscribe(
      (response: any) => {
        if (response?.statusCode === '21000') {
          this._userStorageService.signOut();
          this.currentUserAccount = null;
          this.reLogin(loginPayload, true);
          this._authService.isEverythingLoaded$.next(false);
          this.displaySwitchingAccount = false;
          this.providedPassword = '';
        } else if (response?.statusCode === '210100') {
          this.confirm(
            'Invalid Credentials',
            'pi-exclamation-triangle warning'
          );
        } else if (response?.statusCode === '21003') {
          this.confirm(
            'Switching Area Not Found',
            'pi-exclamation-triangle warning'
          );
        } else {
          this.confirm(
            'Account Switched  Error',
            'pi-exclamation-triangle warning'
          );
        }
      },
      (error: any) => {}
    );
  }

  enableOrDisableSwitchButton(): boolean {
    if (
      typeof this.selectedSwitchingAccount === 'object' &&
      this.selectedSwitchingAccount !== null &&
      this.providedPassword
    ) {
      return false;
    }

    return true;
  }

  public async reLogin(credentials: any, isSwitching?: boolean) {
    const payload = {
      username: credentials.username,
      password: credentials.password,
    };

    this._authService
      .login(payload, isSwitching)
      .pipe()
      .subscribe(
        (response) => {
          //this.confirm('Account Switched  Success', 'pi-check-circle  success');
          this._toastSvc.success('Account Switched Successfully');
          this._router.navigateByUrl('/main/dashboard');
        },
        (error) => {
          this.confirm(
            'Account Switched  Error',
            'pi-exclamation-tthis.itemsriangle warning'
          );
        }
      );
  }

  detectChange(event: any) {
    if ('has_map' in event) {
      if (this.leftSideNav !== undefined && this.rightSideNav !== undefined) {
        if (event.has_map) {
          this.leftSideNav.open();
          this.rightSideNav.close();
        } else {
          this.leftSideNav.close();
          this.rightSideNav.close();
        }
      }
    }
  }

  confirm(message: string, icon: string) {
    this._confirmationSvc.confirm({
      key: 'confirm',
      icon: `pi ${icon}`,
      header: 'Information',
      message: message,
      accept: () => {},
    });
  }

  initializeMenu() {
    this.menuItems = [
      {
        label: 'Dashboard',
        icon: 'pi pi-th-large',
        command: () => {},
      },
      {
        label: 'User Profile',
        icon: 'pi pi-user',
        command: () => {
          this._router.navigateByUrl('main/user-management/user-profile');
        },
      },
      {
        label: 'Settings',
        icon: 'pi pi-cog',
        command: () => {},
      },
      {
        label: 'Sign out',
        icon: 'pi pi-lock',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  showUpdatePhoneNumberNotification() {
    const _ref = this._dialogSvc.open(WelcomeNotificationComponent, {
      header: 'Welcome Back Message',
      showHeader: false,
      width: '40%',
      contentStyle: dialogStyle,
    });
    _ref.onClose.subscribe((res) => {
      if (res) {
        const data = {
          nin: this.user.nindetail?.ninNumber,
          phoneNumber: this.user.mobileNumber,
        };
        const ref = this._dialogSvc.open(UpdateNotificationNumberComponent, {
          showHeader: false,
          width: '27%',
          data,
          contentStyle: dialogStyle,
        });
        ref.onClose.subscribe((message) => {
          if (message) {
            this._interactionSvc.dialog(
              message,
              'pi-exclamation-circle success',
              'Ok'
            );
            this._authService.refreshToken();
          }
        });
      }
    });
  }
}
