import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { OnlineStatusModule } from 'ngx-online-status';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthState } from './authentication/store/auth.state';
import { CoreModule } from './core/core.module';
import { DashboardState } from './dashboard/store/dashboard.state';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { PrivateComponent } from './layouts/private/private.component';
import { PublicComponent } from './layouts/public/public.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ErrorInterceptorProvider } from './shared/interceptors/error.interceptor';
import { AuthService } from './shared/services/auth.service';
import { InteractionService } from './shared/services/interaction.service';
import { NavigationService } from './shared/services/navigation.service';
import { TokenStorageService } from './shared/services/token-storage.service';
import { UserIdleService } from './shared/services/user-idle.service';
import { UserStorageService } from './shared/services/user-storage.service';
import { rootLoaderFactory } from './shared/utils/root.loader';
import { RootState } from './store/root.state';
import { PropertyDashboardState } from './property-tax/store/property-dashboard.state';
import { ParkingDashboardState } from './parking/store/parking-dashboard.state';
import {LandRentDashboardState} from "./land-rent/store/land-rent-dashboard-state.service";
import { ReturnProjectPlotComponent } from './land-sales/pages/return-project-plot/return-project-plot.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    PrivateComponent,
    NotFoundComponent,
    ReturnProjectPlotComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    NgbModule,
    OnlineStatusModule,
    NgxPermissionsModule.forRoot(),
    NgxsModule.forRoot(
      [
        RootState,
        AuthState,
        DashboardState,
        PropertyDashboardState,
        ParkingDashboardState,
        LandRentDashboardState
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [
        RootState,
        AuthState,
        DashboardState,
        PropertyDashboardState,
        ParkingDashboardState,
      ],
    }),
    NgxsLoggerPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [
    TranslateService,
    NavigationService,
    MessageService,
    ConfirmationService,
    DialogService,
    InteractionService,
    CookieService,
    UserIdleService,
    AuthService,
    UserStorageService,
    TokenStorageService,

    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
