import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {ConfirmationService} from "primeng/api";
import {InteractionService} from "../../../shared/services/interaction.service";
import {PdfViewerComponent} from "../../../shared/components/pdf-viewer/pdf-viewer.component";

@Component({
  selector: 'tausi-return-rate-review',
  templateUrl: './return-rate-review.component.html',
  styleUrls: ['./return-rate-review.component.scss']
})
export class ReturnRateReviewComponent implements OnInit {
  project:any;
  public returnRateReviewForm!: FormGroup;
  status: any[] = [{name: 'Yes', key: 2}, {name: 'No', key: 3}];
  constructor(
    public _ref: DynamicDialogRef,
    public _dialogSvc: DialogService,
    public _landSalesSvc: LandSalesService,
    private _confirmationSvc: ConfirmationService,
    private _interactionSvc: InteractionService,
    private _formBuilder: FormBuilder,
    private _config: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) {
    this.project = this._config.data;
  }

  ngOnInit(): void {
    this.returnRateReviewForm = this.initLandForm();
    this.returnRateReviewForm.patchValue({
      projectId:this.project.id
    });
  }

  initLandForm(): FormGroup {
    return this._formBuilder.group({
      projectId: ['', Validators.required],
      remark: ['', [Validators.required,Validators.minLength(5)]],
      status: [null, [Validators.required]]
    });
  }

  close(){
    this._ref.close();
  }

  saveReview(){
    const payload = this.returnRateReviewForm.value;
    const massage = "Are you sure you want to review Return Rate request for <b>" +
      this.project.projectName + " </b> Project?";
    this.confirmationService.confirm({
      message: massage,
      header: 'Return Rate Review Confirmation',
      acceptLabel:'Yes',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._landSalesSvc.projectReturnRateReview(payload).subscribe((response) => {
          if (response.statusCode === '21000') {
            this.confirm(response.description, 'pi-info-circle info');
            setTimeout(() => {
              this._ref.close(response)
            },100);
          } else {
            this._interactionSvc.dialog(
              response.description,
              'pi-exclamation-triangle warning'
            );
          }
        });
      },
      key: "extension"
    });
  }

  confirm(message: string, icon: string) {
    this._confirmationSvc.confirm({
      key: 'extension',
      icon: `pi ${icon}`,
      header: 'Information',
      message: message,
      accept: () => {},
      reject: () => {},
    });
  }

  download(item:any){
    this._landSalesSvc.getPlotReturnAttachment(item.id).subscribe((response) => {
      if (response.statusCode === '21000') {
        const attachment = response.data.item;
        let item = { attachment: attachment, type: 'base64' };

        const viewerRef = this._dialogSvc.open(PdfViewerComponent, {
          header: 'View Attachment',
          showHeader: true,
          width: '70%',
          contentStyle: { 'min-height': '900px' },
          data: item,
        });
      } else {
        this._interactionSvc.dialog(
          response.description,
          'pi-exclamation-triangle warning'
        );
      }
    });
  }

}
