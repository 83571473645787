import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../core/material.module';
import { PrimengModule } from '../core/primeng.module';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EditorModule } from 'primeng/editor';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { MapFilterComponent } from '../land-sales/views/map-filter/map-filter.component';
import { MapInfoComponent } from '../land-sales/views/map-info/map-info.component';
import { AgentDetailsComponent } from './components/agent-details/agent-details.component';
import { AgentMinDetailsComponent } from './components/agent-min-details/agent-min-details.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CollectionPointMapComponent } from './components/collection-point-map/collection-point-map.component';
import { DefaultPageComponent } from './components/default-page/default-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { OneTimePasswordComponent } from './components/one-time-password/one-time-password.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { ProfilePhotoComponent } from './components/profile-photo/profile-photo.component';
import { RegistrationTypeComponent } from './components/registration-type/registration-type.component';
import { SearchCollectorDialogComponent } from './components/search-collector-dialog/search-collector-dialog.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SliderComponent } from './components/slider/slider.component';
import { StepperFormComponent } from './components/stepper-form/stepper-form.component';
import { TaxpayerInfoComponent } from './components/taxpayer-info/taxpayer-info.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { TinInfoComponent } from './components/tin-info/tin-info.component';
import { TinSearchComponent } from './components/tin-search/tin-search.component';
import { TitleComponent } from './components/title/title.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ValidateTaxpayerComponent } from './components/validate-taxpayer/validate-taxpayer.component';
import { BarChartComponent } from './widgets/bar-chart/bar-chart.component';
import { CollectorChartComponent } from './widgets/collector-chart/collector-chart.component';
import { DoughnutChartComponent } from './widgets/doughnut-chart/doughnut-chart.component';
import { InfoCardsComponent } from './widgets/info-cards/info-cards.component';
import { LineChartComponent } from './widgets/line-chart/line-chart.component';
import { MapComponent } from './widgets/map/map.component';
import { OnlineOfflineChartComponent } from './widgets/online-offline-chart/online-offline-chart.component';
import { PieChartComponent } from './widgets/pie-chart/pie-chart.component';
import { PosLocationsMapComponent } from './widgets/pos-locations-map/pos-locations-map.component';
import { PosLocationsComponent } from './widgets/pos-locations/pos-locations.component';
import { PosUsageChartComponent } from './widgets/pos-usage-chart/pos-usage-chart.component';
import { TableDataComponent } from './widgets/table-data/table-data.component';
import { TableReportComponent } from './widgets/table-report/table-report.component';
import { FormResetPasswordComponent } from './components/form-reset-password/form-reset-password.component';
import { OtpResetPasswordComponent } from './components/otp-reset-password/otp-reset-password.component';
import { DashboardDataComponent } from './widgets/dashboard-data/dashboard-data.component';
import { CustomInfoCardComponent } from './widgets/custom-info-card/custom-info-card.component';
// import { DynamicChartComponent } from './widgets/dynamic-chart/dynamic-chart.component';
import { NumberWithPrefixPipe } from './utils/custom.pipe';
import { LocationOnMapComponent } from './components/location-on-map/location-on-map.component';
import { WelcomeNotificationComponent } from './components/welcome-notification/welcome-notification.component';
import { UpdateNotificationNumberComponent } from './components/update-notification-number/update-notification-number.component';
import { SharedFooterComponent } from './components/shared-footer/shared-footer.component';
import {AdminHierarchyTreeComponent} from "./components/admin-hierarchy-tree/admin-hierarchy-tree.component";

@NgModule({
  declarations: [
    LogoComponent,
    SliderComponent,
    FooterComponent,
    TitleComponent,
    MenuItemComponent,
    AvatarComponent,
    ToolbarComponent,
    BreadcrumbComponent,
    TinSearchComponent,
    LoaderComponent,
    MapFilterComponent,
    MapInfoComponent,
    ValidateTaxpayerComponent,
    SidebarMenuComponent,
    CollectionPointMapComponent,
    PosLocationsMapComponent,
    MapComponent,
    AgentDetailsComponent,
    SearchResultsComponent,
    TermsConditionsComponent,
    AdminHierarchyTreeComponent,

    // all charts declartions
    BarChartComponent,
    DoughnutChartComponent,
    PieChartComponent,
    InfoCardsComponent,
    PosLocationsComponent,
    LineChartComponent,
    TableDataComponent,
    StepperFormComponent,
    TableReportComponent,
    AgentMinDetailsComponent,
    SearchCollectorDialogComponent,
    PdfViewerComponent,
    ProfilePhotoComponent,
    DefaultPageComponent,
    PageTitleComponent,
    RegistrationTypeComponent,
    OneTimePasswordComponent,
    TaxpayerInfoComponent,
    TinInfoComponent,
    CollectorChartComponent,
    PosUsageChartComponent,
    OnlineOfflineChartComponent,
    SkeletonComponent,
    FormResetPasswordComponent,
    OtpResetPasswordComponent,
    DashboardDataComponent,
    CustomInfoCardComponent,
    NumberWithPrefixPipe,
    LocationOnMapComponent,
    WelcomeNotificationComponent,
    UpdateNotificationNumberComponent,
    SharedFooterComponent,
    // DynamicChartComponent,
  ],
  imports: [
    CommonModule,
    PrimengModule,
    MaterialModule,
    BreadcrumbModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    EditorModule,
    TranslateModule,
    NgOtpInputModule,
    NgxExtendedPdfViewerModule,
    // NgxPermissionsModule.forChild()
  ],
  exports: [
    LogoComponent,
    SliderComponent,
    FooterComponent,
    TitleComponent,
    MenuItemComponent,
    AvatarComponent,
    ToolbarComponent,
    BreadcrumbComponent,
    LoaderComponent,
    SidebarMenuComponent,
    CollectionPointMapComponent,
    PosLocationsMapComponent,
    MapComponent,
    AgentDetailsComponent,
    AgentMinDetailsComponent,
    SearchCollectorDialogComponent,
    ProfilePhotoComponent,

    // all charts exports
    BarChartComponent,
    DoughnutChartComponent,
    PieChartComponent,
    InfoCardsComponent,
    PosLocationsComponent,
    LineChartComponent,
    TableDataComponent,
    StepperFormComponent,
    TableReportComponent,
    DefaultPageComponent,
    PageTitleComponent,
    RegistrationTypeComponent,
    OneTimePasswordComponent,
    MapInfoComponent,
    MapFilterComponent,
    PosUsageChartComponent,
    OnlineOfflineChartComponent,
    SkeletonComponent,
    // all modules exports
    PrimengModule,
    MaterialModule,
    BreadcrumbModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgbModule,
    EditorModule,
    TranslateModule,
    NgOtpInputModule,
    NgxExtendedPdfViewerModule,
    DashboardDataComponent,
    CustomInfoCardComponent,
    NumberWithPrefixPipe,
    SharedFooterComponent,
    AdminHierarchyTreeComponent
  ],
  entryComponents: [
    TinSearchComponent,
    ValidateTaxpayerComponent,
    SearchResultsComponent,
    StepperFormComponent,
    SearchCollectorDialogComponent,
    PdfViewerComponent,
    OneTimePasswordComponent,
    RegistrationTypeComponent,
    TermsConditionsComponent,
  ],
})
export class SharedModule {}
