<div class="home-footer">
  <div  class="container">
    <div  class="p-align-center col-10">
      <div class="p-flex border-bottom-white">
        <div class="">
          <span class="text-white "><b class="mr-3">Contact Us  </b> <i class="pi pi-phone mr-3 text-300"> 026 785 889 889</i> <i class="pi pi-inbox text-300"> helpdesk@tamisemi.go.tz</i> </span>
        </div>
        <div  class="">
          <img width="35" height="35" [src]="'/assets/imgs/tausi_logo.png'" [alt]="'complain'"/>
        </div>
      </div>
      <div class="terms">
        <div class="left">
          <span class="mr-2 text-300">Terms & conditions</span>
          <span class="mr-2 text-300">|</span>
          <span class="mr-2 text-300">Privacy Policy</span>
          <span class="mr-2 text-300">|</span>
          <span class="mr-2 text-300"><a class="text-300 text-decoration-none"  href="https://www.tamisemi.go.tz/" target="_blank">Our website</a></span>
          <span class="mr-2 text-300">|</span>
          <span class="text-300">Resources</span>
        </div>
        <div class="right align-items-center gap-2 justify-content-end">
          <span class="text-white"><b>Our Socials</b></span>
          <span class="pl-2 pr-2 text-300">|</span>
          <a><i class="bi bi-instagram text-300"></i></a>
          <a><i class="i bi-youtube text-300"></i></a>
          <a><i class="bi bi-facebook  text-300"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
