import { Component, OnInit } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {ConfirmationService} from "primeng/api";
import {InteractionService} from "../../../shared/services/interaction.service";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'tausi-extension-details',
  templateUrl: './extension-details.component.html',
  styleUrls: ['./extension-details.component.scss']
})
export class ExtensionDetailsComponent implements OnInit {
  extensionDetails:any[]=[];
  project:any;
  constructor(
    public _ref: DynamicDialogRef,
    public _dialogSvc: DialogService,
    public _landSalesSvc: LandSalesService,
    private _confirmationSvc: ConfirmationService,
    private _interactionSvc: InteractionService,
    private _formBuilder: FormBuilder,
    private _config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) {
    this.project = this._config.data;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadDetails(this.project.requestCode);
    });
  }

  close(){
    this._ref.close();
  }

  loadDetails(requestCode:any){
    this._landSalesSvc.getExtensionDetails(requestCode,0,100).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.extensionDetails = response.data.itemList??[];
      } else {
        this._interactionSvc.dialog(
          response.description,
          'pi-exclamation-triangle warning'
        );
      }
    });
  }

}
