import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  parkingHours,
  parkingHour,
  parkingHourReview,
} from 'src/app/parking/models/parking_hour.model';
import { environment } from 'src/environments/environment';
import { NotificationThreshold } from '../../parking/models/notification-threshold.model';
import { CustomResponse } from '../utils/custom-response';
import { NotificationThresholdReview } from '../../parking/models/notification-threshold-review.model';
import {
  AssessmentCriteria,
  DepartmentLevelSetup,
  ParkingServices,
  ResponseOption,
} from '../../parking/models/parking-services.model';
import { PaymentPlan } from '../../parking/models/payment-plan.model';
import { CouncilParkingDay } from '../../parking/models/council-parking-day.model';
import { ChargeCategoryPaymentPlan } from '../../parking/models/charge-category-payment-plan.model';
import { VehicleCategory } from '../../parking/models/vehicle-category.model';
import { ParkingRate } from '../../parking/models/parking-rate.model';
import { ParkingRateReview } from '../../parking/models/parking-rate-review.model';
import { ParkingServiceVehicleCategory } from '../../parking/models/parking-service-vehicle-category.model';
import { Subscription } from '../../parking/models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class ParkingService {
  private base_parking_uri: string = `${environment.apiUrl}${environment.endPoints.parking}`;

  constructor(private _httpSvc: HttpClient) {}

  getValidParkingHour(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/valid-parking-hour`,
      {
        params: payload,
      }
    );
  }

  createValidParkingHour(payload: parkingHours): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/valid-parking-hour`,
      payload
    );
  }

  createParkingCouncil(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/council`,
      payload
    );
  }

  activateParkingCouncil(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/activate-council`,
      payload
    );
  }

  deactivateParkingCouncil(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/deactivate-council`,
      payload
    );
  }

  getIncompleteSetup(councilCode: string, params: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_parking_uri}/api/v1/get-incomplete-setup/councilCode/${councilCode}`,
      {
        params: params,
      }
    );
  }

  getParkingCouncil(params: any): Observable<CustomResponse<any>> {
    return this._httpSvc.get<CustomResponse<any>>(
      `${this.base_parking_uri}/api/v1/council`,
      {
        params: params,
      }
    );
  }

  updateValidParkingHour(payload: parkingHour): Observable<any> {
    return this._httpSvc.put(
      `${this.base_parking_uri}/api/v1/valid-parking-hour/id/${payload?.id}`,
      payload
    );
  }

  deleteValidParkingHour(payload: parkingHour): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_parking_uri}/api/v1/valid-parking-hour/id/${payload.id}`
    );
  }

  reviewValidParkingHour(payload: parkingHourReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/valid-parking-hour-review`,
      payload
    );
  }

  getParkingHourReview(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/valid-parking-hour-review`,
      payload
    );
  }

  getExceptedParkingDate(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/excepted-parking-day`,
      {
        params: payload,
      }
    );
  }

  createExceptedParkingDate(payload: parkingHours): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/excepted-parking-day`,
      payload
    );
  }

  updateExceptedParkingDate(payload: parkingHour): Observable<any> {
    return this._httpSvc.put(
      `${this.base_parking_uri}/api/v1/excepted-parking-day/id/${payload?.id}`,
      payload
    );
  }

  deleteExceptedParkingDate(payload: parkingHour): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_parking_uri}/api/v1/excepted-parking-day/id/${payload.id}`
    );
  }

  reviewExceptedParkingDate(payload: parkingHourReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/excepted-parking-day-review`,
      payload
    );
  }

  /** council parking day review */
  reviewCouncilParkingDate(payload: parkingHourReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/council-parking-day-review`,
      payload
    );
  }
  /** Start NotificationThreshold methods */

  createNotificationThreshold(
    notificationThreshold: NotificationThreshold
  ): Observable<CustomResponse<NotificationThreshold>> {
    return this._httpSvc.post<CustomResponse<NotificationThreshold>>(
      `${this.base_parking_uri}/api/v1/notification-threshold`,
      notificationThreshold
    );
  }

  updateNotificationThreshold(
    notificationThreshold: NotificationThreshold
  ): Observable<CustomResponse<NotificationThreshold>> {
    return this._httpSvc.put<CustomResponse<NotificationThreshold>>(
      `${this.base_parking_uri}/api/v1/notification-threshold/id/${notificationThreshold.id}`,
      notificationThreshold
    );
  }

  findNotificationThreshold(
    uid: string
  ): Observable<CustomResponse<NotificationThreshold>> {
    return this._httpSvc.get<CustomResponse<NotificationThreshold>>(
      `${this.base_parking_uri}/api/v1/notification-threshold/${uid}`
    );
  }

  getNotificationThreshold(
    payload: any
  ): Observable<CustomResponse<NotificationThreshold[]>> {
    return this._httpSvc.get<CustomResponse<NotificationThreshold[]>>(
      `${this.base_parking_uri}/api/v1/notification-threshold`,
      {
        params: payload,
      }
    );
  }

  deleteNotificationThreshold(
    id: string
  ): Observable<CustomResponse<NotificationThreshold>> {
    return this._httpSvc.delete<CustomResponse<NotificationThreshold>>(
      `${this.base_parking_uri}/api/v1/notification-threshold/id/${id}`
    );
  }
  /** End NotificationThreshold methods */

  /** Start Notification threshold review */
  createNotificationThresholdReview(
    notificationThresholdReview: NotificationThresholdReview
  ): Observable<CustomResponse<NotificationThresholdReview>> {
    return this._httpSvc.post<CustomResponse<NotificationThresholdReview>>(
      `${this.base_parking_uri}/api/v1/notification-threshold-review`,
      notificationThresholdReview
    );
  }

  updateNotificationThresholdReview(
    notificationThresholdReview: NotificationThresholdReview
  ): Observable<CustomResponse<NotificationThresholdReview>> {
    return this._httpSvc.put<CustomResponse<NotificationThresholdReview>>(
      `${this.base_parking_uri}/api/v1/notification-threshold-review/id/${notificationThresholdReview.id}`,
      notificationThresholdReview
    );
  }

  findNotificationThresholdReview(
    uid: string
  ): Observable<CustomResponse<NotificationThresholdReview>> {
    return this._httpSvc.get<CustomResponse<NotificationThresholdReview>>(
      `${this.base_parking_uri}/api/v1/notification-threshold-review/${uid}`
    );
  }

  getNotificationThresholdReview(
    payload: any
  ): Observable<CustomResponse<NotificationThresholdReview[]>> {
    return this._httpSvc.get<CustomResponse<NotificationThresholdReview[]>>(
      `${this.base_parking_uri}/api/v1/notification-threshold-review`,
      {
        params: payload,
      }
    );
  }

  deleteNotificationThresholdReview(
    id: string
  ): Observable<CustomResponse<NotificationThresholdReview>> {
    return this._httpSvc.delete<CustomResponse<NotificationThresholdReview>>(
      `${this.base_parking_uri}/api/v1/notification-threshold-review/id/${id}`
    );
  }
  /** End Notification threshold review */

  /** Start of Parking services */

  createParkingServices(
    parkingServices: ParkingServices
  ): Observable<CustomResponse<ParkingServices>> {
    return this._httpSvc.post<CustomResponse<ParkingServices>>(
      `${this.base_parking_uri}/api/v1/parking-service`,
      parkingServices
    );
  }

  updateParkingServices(
    parkingServices: ParkingServices
  ): Observable<CustomResponse<ParkingServices>> {
    return this._httpSvc.put<CustomResponse<ParkingServices>>(
      `${this.base_parking_uri}/api/v1/parking-service/id/${parkingServices.id}`,
      parkingServices
    );
  }

  findParkingServices(
    uid: string
  ): Observable<CustomResponse<ParkingServices>> {
    return this._httpSvc.get<CustomResponse<ParkingServices>>(
      `${this.base_parking_uri}/api/v1/parking-service/${uid}`
    );
  }

  getParkingServices(
    payload: any
  ): Observable<CustomResponse<ParkingServices[]>> {
    return this._httpSvc.get<CustomResponse<ParkingServices[]>>(
      `${this.base_parking_uri}/api/v1/parking-service`,
      {
        params: payload,
      }
    );
  }

  deleteParkingServices(
    id: string
  ): Observable<CustomResponse<ParkingServices>> {
    return this._httpSvc.delete<CustomResponse<ParkingServices>>(
      `${this.base_parking_uri}/api/v1/parking-service/id/${id}`
    );
  }

  deleteParkingServiceVehicleCategory(
    vehicleCategoryId: string,
    parkingServiceId: string
  ): Observable<any> {
    return this._httpSvc.delete<CustomResponse<ParkingServices>>(
      `${this.base_parking_uri}/api/v1/parking-service-vehicle-category/id/${vehicleCategoryId}/parkingServiceId/${parkingServiceId}`
    );
  }
  /** End of Parking services */

  /** Start of Payment Plan */
  createPaymentPlan(
    paymentPlan: PaymentPlan
  ): Observable<CustomResponse<PaymentPlan>> {
    return this._httpSvc.post<CustomResponse<PaymentPlan>>(
      `${this.base_parking_uri}/api/v1/payment-plan`,
      paymentPlan
    );
  }

  updatePaymentPlan(
    paymentPlan: PaymentPlan
  ): Observable<CustomResponse<PaymentPlan>> {
    return this._httpSvc.put<CustomResponse<PaymentPlan>>(
      `${this.base_parking_uri}/api/v1/payment-plan/id/${paymentPlan.id}`,
      paymentPlan
    );
  }

  findPaymentPlan(uid: string): Observable<CustomResponse<PaymentPlan>> {
    return this._httpSvc.get<CustomResponse<PaymentPlan>>(
      `${this.base_parking_uri}/api/v1/payment-plan/${uid}`
    );
  }

  getPaymentPlan(payload?: any): Observable<CustomResponse<PaymentPlan[]>> {
    return this._httpSvc.get<CustomResponse<PaymentPlan[]>>(
      `${this.base_parking_uri}/api/v1/payment-plan`,
      {
        params: payload,
      }
    );
  }

  deletePaymentPlan(id: string): Observable<CustomResponse<PaymentPlan>> {
    return this._httpSvc.delete<CustomResponse<PaymentPlan>>(
      `${this.base_parking_uri}/api/v1/payment-plan/id/${id}`
    );
  }
  /** End of Payment Plan */

  /** Start Council Parking Day */
  createCouncilParkingDay(
    councilParkingDay: CouncilParkingDay
  ): Observable<CustomResponse<CouncilParkingDay>> {
    return this._httpSvc.post<CustomResponse<CouncilParkingDay>>(
      `${this.base_parking_uri}/api/v1/council-parking-day`,
      councilParkingDay
    );
  }

  updateCouncilParkingDay(
    councilParkingDay: CouncilParkingDay
  ): Observable<CustomResponse<CouncilParkingDay>> {
    return this._httpSvc.put<CustomResponse<CouncilParkingDay>>(
      `${this.base_parking_uri}/api/v1/council-parking-day/id/${councilParkingDay.id}`,
      councilParkingDay
    );
  }

  findCouncilParkingDay(
    uid: string
  ): Observable<CustomResponse<CouncilParkingDay>> {
    return this._httpSvc.get<CustomResponse<CouncilParkingDay>>(
      `${this.base_parking_uri}/api/v1/council-parking-day/${uid}`
    );
  }

  getCouncilParkingDay(
    payload: any
  ): Observable<CustomResponse<CouncilParkingDay[]>> {
    return this._httpSvc.get<CustomResponse<CouncilParkingDay[]>>(
      `${this.base_parking_uri}/api/v1/council-parking-day`,
      {
        params: payload,
      }
    );
  }

  deleteCouncilParkingDay(
    id: string
  ): Observable<CustomResponse<CouncilParkingDay>> {
    return this._httpSvc.delete<CustomResponse<CouncilParkingDay>>(
      `${this.base_parking_uri}/api/v1/council-parking-day/id/${id}`
    );
  }

  /** End Council Parking Day */

  getChargeCategoryPaymentPlan(
    chargeCategoryId: string
  ): Observable<CustomResponse<ChargeCategoryPaymentPlan[]>> {
    return this._httpSvc.get<CustomResponse<ChargeCategoryPaymentPlan[]>>(
      `${this.base_parking_uri}/api/v1/charge-category-payment-plan/charge-category/${chargeCategoryId}`
    );
  }

  createChargeCategoryPaymentPlan(
    chargeCategoryPaymentPlan: ChargeCategoryPaymentPlan
  ): Observable<CustomResponse<ChargeCategoryPaymentPlan>> {
    return this._httpSvc.post<CustomResponse<ChargeCategoryPaymentPlan>>(
      `${this.base_parking_uri}/api/v1/charge-category-payment-plan`,
      chargeCategoryPaymentPlan
    );
  }

  deleteChargeCategoryPaymentPlan(
    id: string
  ): Observable<CustomResponse<ChargeCategoryPaymentPlan>> {
    return this._httpSvc.delete<CustomResponse<ChargeCategoryPaymentPlan>>(
      `${this.base_parking_uri}/api/v1/charge-category-payment-plan/id/${id}`
    );
  }

  getVehicleCategories(
    payload: any
  ): Observable<CustomResponse<VehicleCategory[]>> {
    return this._httpSvc.get<CustomResponse<VehicleCategory[]>>(
      `${this.base_parking_uri}/api/v1/vehicle-category`,
      {
        params: payload,
      }
    );
  }

  getParkingServiceVehicleCategories(
    payload: any,
    parkingServiceId: string
  ): Observable<CustomResponse<any[]>> {
    return this._httpSvc.get<CustomResponse<any[]>>(
      `${this.base_parking_uri}/api/v1/parking-service-vehicle-category/parkingServiceId/${parkingServiceId}`,
      {
        params: payload,
      }
    );
  }

  getVehicleCategoriesForParkingRate(
    payload: any
  ): Observable<CustomResponse<VehicleCategory[]>> {
    return this._httpSvc.get<CustomResponse<VehicleCategory[]>>(
      `${this.base_parking_uri}/api/v1/vehicle-category-for-setup`,
      {
        params: payload,
      }
    );
  }

  findPaymentPlansByParkingService(
    parkingServiceId: string
  ): Observable<CustomResponse<PaymentPlan[]>> {
    return this._httpSvc.get<CustomResponse<PaymentPlan[]>>(
      `${this.base_parking_uri}/api/v1/charge-category-payment-plan/parkingServiceId/${parkingServiceId}`
    );
  }

  /** Start Parking rates */
  createParkingRate(
    parkingRate: ParkingRate
  ): Observable<CustomResponse<ParkingRate>> {
    return this._httpSvc.post<CustomResponse<ParkingRate>>(
      `${this.base_parking_uri}/api/v1/parking-rate`,
      parkingRate
    );
  }

  parkingServiceVehicleCategory(
    parkingServiceVehicleCategory: ParkingServiceVehicleCategory
  ): Observable<CustomResponse<ParkingServiceVehicleCategory>> {
    return this._httpSvc.post<CustomResponse<ParkingServiceVehicleCategory>>(
      `${this.base_parking_uri}/api/v1/parking-service-vehicle-category`,
      parkingServiceVehicleCategory
    );
  }

  updateParkingRate(
    parkingRate: ParkingRate
  ): Observable<CustomResponse<ParkingRate>> {
    return this._httpSvc.put<CustomResponse<ParkingRate>>(
      `${this.base_parking_uri}/api/v1/parking-rate/id/${parkingRate.id}`,
      parkingRate
    );
  }

  updateSubscription(
    subscription: Subscription
  ): Observable<CustomResponse<Subscription>> {
    return this._httpSvc.post<CustomResponse<Subscription>>(
      `${this.base_parking_uri}/api/v1/update-subscription-plan`,
      subscription
    );
  }

  subscriptionTraRequest(id: string): Observable<CustomResponse<Subscription>> {
    return this._httpSvc.delete<CustomResponse<Subscription>>(
      `${this.base_parking_uri}/api/v1/subscription-tra-request/id/${id}`
    );
  }

  deleteParkingRate(id: string): Observable<CustomResponse<ParkingRate>> {
    return this._httpSvc.delete<CustomResponse<ParkingRate>>(
      `${this.base_parking_uri}/api/v1/parking-rate/id/${id}`
    );
  }

  getParkingServiceWithChargeRate(
    payload: any
  ): Observable<CustomResponse<ParkingRate[]>> {
    return this._httpSvc.get<CustomResponse<ParkingRate[]>>(
      `${this.base_parking_uri}/api/v1/get-parking-service-with-charge-rate`,
      {
        params: payload,
      }
    );
  }

  getSubscription(payload: any): Observable<CustomResponse<Subscription[]>> {
    return this._httpSvc.get<CustomResponse<Subscription[]>>(
      `${this.base_parking_uri}/api/v1/subscription-plan`,
      {
        params: payload,
      }
    );
  }

  /** End Parking rates */

  /** Start Parking rates Review */
  createParkingRateReview(
    parkingRateReview: ParkingRateReview
  ): Observable<CustomResponse<ParkingRateReview>> {
    return this._httpSvc.post<CustomResponse<ParkingRateReview>>(
      `${this.base_parking_uri}/api/v1/parking-rate-review`,
      parkingRateReview
    );
  }
  /** Start Parking rates Review */
  createAssessmentCriteria(
    assessmentCriteria: AssessmentCriteria
  ): Observable<CustomResponse<AssessmentCriteria>> {
    return this._httpSvc.post<CustomResponse<AssessmentCriteria>>(
      `${this.base_parking_uri}/api/v1/assessment-criteria`,
      assessmentCriteria
    );
  }

  getAssessmentCriteria(
    payload: any
  ): Observable<CustomResponse<AssessmentCriteria[]>> {
    return this._httpSvc.get<CustomResponse<AssessmentCriteria[]>>(
      `${this.base_parking_uri}/api/v1/assessment-criteria`,
      {
        params: payload,
      }
    );
  }

  updateAssessmentCriteria(
    assessmentCriteria: AssessmentCriteria,
    id: string
  ): Observable<CustomResponse<AssessmentCriteria>> {
    return this._httpSvc.put<CustomResponse<AssessmentCriteria>>(
      `${this.base_parking_uri}/api/v1/assessment-criteria/id/${id}`,
      assessmentCriteria
    );
  }

  deleteAssessmentCriteria(
    id: string
  ): Observable<CustomResponse<AssessmentCriteria>> {
    return this._httpSvc.delete<CustomResponse<AssessmentCriteria>>(
      `${this.base_parking_uri}/api/v1/assessment-criteria/id/${id}`
    );
  }

  createResponseOption(
    responseOption: ResponseOption
  ): Observable<CustomResponse<ResponseOption>> {
    return this._httpSvc.post<CustomResponse<ResponseOption>>(
      `${this.base_parking_uri}/api/v1/response-option`,
      responseOption
    );
  }

  getResponseOptions(payload: any): Observable<CustomResponse<ResponseOption>> {
    return this._httpSvc.get<CustomResponse<ResponseOption>>(
      `${this.base_parking_uri}/api/v1/response-option`,
      { params: payload }
    );
  }

  updateResponseOption(
    responseOption: ResponseOption,
    id: string
  ): Observable<CustomResponse<ResponseOption>> {
    return this._httpSvc.put<CustomResponse<ResponseOption>>(
      `${this.base_parking_uri}/api/v1/response-option/id/${id}`,
      responseOption
    );
  }

  deleteResponseOption(id: string): Observable<CustomResponse<ResponseOption>> {
    return this._httpSvc.delete<CustomResponse<ResponseOption>>(
      `${this.base_parking_uri}/api/v1/response-option/id/${id}`
    );
  }

  /** department level setup */
  createDepartmentSetup(
    departmentSetup: DepartmentLevelSetup
  ): Observable<CustomResponse<DepartmentLevelSetup>> {
    return this._httpSvc.post<CustomResponse<DepartmentLevelSetup>>(
      `${this.base_parking_uri}/api/v1/department-review-level`,
      departmentSetup
    );
  }

  getDepartmentSetup(
    payload: any
  ): Observable<CustomResponse<DepartmentLevelSetup>> {
    return this._httpSvc.get<CustomResponse<DepartmentLevelSetup>>(
      `${this.base_parking_uri}/api/v1/department-review-level`,
      { params: payload }
    );
  }

  updateDepartmentSetup(
    departmentSetup: DepartmentLevelSetup,
    id: string
  ): Observable<CustomResponse<DepartmentLevelSetup>> {
    return this._httpSvc.put<CustomResponse<DepartmentLevelSetup>>(
      `${this.base_parking_uri}/api/v1/department-review-level/id/${id}`,
      departmentSetup
    );
  }

  deleteDepartmentSetup(
    id: string
  ): Observable<CustomResponse<DepartmentLevelSetup>> {
    return this._httpSvc.delete<CustomResponse<DepartmentLevelSetup>>(
      `${this.base_parking_uri}/api/v1/department-review-level/id/${id}`
    );
  }

  setupCompleteness(): Observable<CustomResponse<any[]>> {
    return this._httpSvc.get<CustomResponse<any[]>>(
      `${this.base_parking_uri}/api/v1/council-setup-completeness`
    );
  }

  /** get departments */
  getDepartments(payload: any): Observable<CustomResponse<any[]>> {
    return this._httpSvc.get<CustomResponse<any[]>>(
      `${this.base_parking_uri}/api/v1/departments`,
      { params: payload }
    );
  }

  /** get vehicle types */
  getVehicleTypes(payload: any): Observable<CustomResponse<any[]>> {
    return this._httpSvc.get<CustomResponse<any[]>>(
      `${this.base_parking_uri}/api/v1/vehicle-type`,
      { params: payload }
    );
  }

  /** create vehicle category */
  createVehicleCategory(
    vehicleCategory: VehicleCategory
  ): Observable<CustomResponse<VehicleCategory>> {
    return this._httpSvc.post<CustomResponse<VehicleCategory>>(
      `${this.base_parking_uri}/api/v1/vehicle-category`,
      vehicleCategory
    );
  }

  updateVehicleCategory(
    vehicleCategory: VehicleCategory,
    id: string
  ): Observable<CustomResponse<VehicleCategory>> {
    return this._httpSvc.put<CustomResponse<VehicleCategory>>(
      `${this.base_parking_uri}/api/v1/vehicle-category/id/${id}`,
      vehicleCategory
    );
  }

  deleteVehicleCategory(
    id: string
  ): Observable<CustomResponse<VehicleCategory>> {
    return this._httpSvc.delete<CustomResponse<VehicleCategory>>(
      `${this.base_parking_uri}/api/v1/vehicle-category/id/${id}`
    );
  }

  /** get user department */
  getUserDepartment(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/user-department-approval-decision`
    );
  }

  /** get assessment */
  getApplicationAssessment(applicationId: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/get-application-assessment/id/${applicationId}`
    );
  }

  /*** get bad scans */
  searchBadScans(
    params: any,
    pageSize: number,
    pageNo: number
  ): Observable<CustomResponse<any>> {
    return this._httpSvc.post<CustomResponse<any>>(
      `${this.base_parking_uri}/api/v1/parking-detail/search/bad-scan?pageSize=${pageSize}&pageNo=${pageNo}`,
      params
    );
  }

  /** dashboard data */
  getDashboardData(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/parking-detail/dashboard`
    );
  }

  /** vehicle scan history report */
  getVehicleScans(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/parking-detail/vehicle-scan-report/client`,
      payload
    );
  }

  getCollectionSource(payload: any, councilCode: string): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/collection-source?councilCode=${councilCode}`,
      payload
    );
  }

  getReserveParking(payload: any, councilCode: string): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/reserve_parking_permit?councilCode=${councilCode}`,
      payload
    );
  }

  getVehicleScanByCouncil(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/vehicle-scan-council`,
      payload
    );
  }

  getVehicleScan(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/vehicle-scan`,
      payload
    );
  }

  getCollectionByCouncil(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/collection-by-council`,
      payload
    );
  }

  getVerificationStatus(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/scan-verification-status`,
      payload
    );
  }
  getCustomerComplaint(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/customer-complaint-status`,
      payload
    );
  }

  getAdjustmentSummaryReport(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/adjustment-summary-report?plateNumber=${payload?.plateNumber}`,
      payload
    );
  }

  getAdjustmentReport(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_parking_uri}/api/v1/report/adjustment-report`,
      payload
    );
  }

  getUnverifiedScanReport(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_parking_uri}/api/v1/report/unverified-vehicle-scan`
    );
  }
}
