<div class="container-fluid mt-3">
  <div fxLayout="flex-row" fxLayoutAlign="space-between center">
    <div class="tausi-content-header-outer">
      <h4>Project Plot Return Request</h4>
    </div>
  </div>
  <div class="mt-2">
    <p-card class="p-shadow-15">
      <div class="tabs-container p-mt-5">
        <ul
          ngbNav
          [(activeId)]="activeIndex"
          #nav="ngbNav"
          class="nav-tabs d-flex justify-content-center align-items-center"
        >
          <li ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>PENDING</a>
            <ng-template ngbNavContent>
              <div class="mx-1">
                <p-table
                  [value]="projectPendingReturn"
                  responsiveLayout="scroll"
                  [rows]="10"
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [lazy]="true"
                  (onLazyLoad)="pageChange($event)"
                  [totalRecords]="totalRecords"
                  [paginator]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th rowspan="2">No.</th>
                      <th rowspan="2" pSortableColumn="projectName">
                        Project Name
                      </th>
                      <th rowspan="2" style="width: 50%" pSortableColumn="projectDescription">
                        Project Description
                      </th>
                      <th style="text-align:center" colspan="2">
                        Return Rate(%)
                      </th>
                      <th rowspan="2">Attachment</th>
                      <th rowspan="2" style="width: 10%; text-align: center">Actions</th>
                    </tr>
                    <tr style="text-align:center">
                      <th>Current</th>
                      <th>Previous</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item let-index="rowIndex">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.landProject.projectName }}</td>
                      <td style="padding:.8em !important;">{{ item.landProject.projectDescription }}</td>
                      <td style="text-align: center"><b>{{item?.currRate}}</b></td>
                      <td style="text-align: center"><b>{{item?.prevRate}}</b></td>
                      <td style="text-align: center;">
                        <button
                          pButton
                          pTooltip="View Attachment"
                          tooltipPosition="left"
                          tooltipTrigger="hover"
                          icon="pi pi-download"
                          class="p-button-text p-button-rounded"
                          (click)="download(item)"
                        ></button>
                      </td>
                      <td style="width: 10%; text-align: center">
                        <button
                          pButton
                          pTooltip="Manage Project Return Request"
                          tooltipPosition="left"
                          tooltipTrigger="hover"
                          icon="pi pi-ellipsis-v"
                          class="p-button-text p-button-rounded"
                          (click)="togglePendingMenu(pendingMenu, $event, item)"
                        ></button>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="emptymessage"
                    *ngIf="projectPendingReturn.length == 0"
                  >
                    <tr>
                      <td [attr.colspan]="9">No data found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>REVIEWED</a>
            <ng-template ngbNavContent>
              <div class="mx-1">
                <p-table
                  [value]="projectReviewedReturn"
                  responsiveLayout="scroll"
                  [rows]="10"
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [lazy]="true"
                  (onLazyLoad)="reviewedPageChange($event)"
                  [totalRecords]="totalRecords"
                  [paginator]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th rowspan="2">No.</th>
                      <th rowspan="2" pSortableColumn="projectName">
                        Project Name
                      </th>
                      <th rowspan="2" style="width: 50%" pSortableColumn="projectDescription">
                        Project Description
                      </th>
                      <th style="text-align:center" colspan="2">
                        Return Rate(%)
                      </th>
                      <th rowspan="2">Attachment</th>
                      <th rowspan="2" style="width: 10%; text-align: center">Actions</th>
                    </tr>
                    <tr style="text-align:center">
                      <th>Current</th>
                      <th>Previous</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item let-index="rowIndex">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <<td>{{ item.landProject.projectName }}</td>
                      <td style="padding:.8em !important;">{{ item.landProject.projectDescription }}</td>
                      <td style="text-align: center"><b>{{item?.currRate}}</b></td>
                      <td style="text-align: center"><b>{{item?.prevRate}}</b></td>
                      <td style="text-align: center;">
                        <button
                          pButton
                          pTooltip="View Attachment"
                          tooltipPosition="left"
                          tooltipTrigger="hover"
                          icon="pi pi-download"
                          class="p-button-text p-button-rounded"
                          (click)="download(item)"
                        ></button>
                      </td>
                      <td>{{item.remark}}</td>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="emptymessage"
                    *ngIf="projectReviewedReturn.length == 0"
                  >
                    <tr>
                      <td [attr.colspan]="10">No data found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink>REJECTED</a>
            <ng-template ngbNavContent>
              <div class="mx-1">
                <p-table
                  [value]="projectReviewedReturn"
                  responsiveLayout="scroll"
                  [rows]="10"
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [lazy]="true"
                  (onLazyLoad)="reviewedPageChange($event)"
                  [totalRecords]="totalRecords"
                  [paginator]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th rowspan="2">No.</th>
                      <th rowspan="2" pSortableColumn="projectName">
                        Project Name
                      </th>
                      <th rowspan="2" style="width: 50%" pSortableColumn="projectDescription">
                        Project Description
                      </th>
                      <th style="text-align:center" colspan="2">
                        Return Rate(%)
                      </th>
                      <th rowspan="2">Attachment</th>
                      <th rowspan="2" style="width: 10%; text-align: center">Actions</th>
                    </tr>
                    <tr style="text-align:center">
                      <th>Current</th>
                      <th>Previous</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item let-index="rowIndex">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.landProject.projectName }}</td>
                      <td style="padding:.8em !important;">{{ item.landProject.projectDescription }}</td>
                      <td style="text-align: center"><b>{{item?.currRate}}</b></td>
                      <td style="text-align: center"><b>{{item?.prevRate}}</b></td>
                      <td style="text-align: center;">
                        <button
                          pButton
                          pTooltip="View Attachment"
                          tooltipPosition="left"
                          tooltipTrigger="hover"
                          icon="pi pi-download"
                          class="p-button-text p-button-rounded"
                          (click)="download(item)"
                        ></button>
                      </td>
                      <td>{{item.remark}}</td>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="emptymessage"
                    *ngIf="projectReviewedReturn.length == 0"
                  >
                    <tr>
                      <td [attr.colspan]="10">No data found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="full-height"></div>
      </div>
    </p-card>
  </div>
</div>
<p-menu
  appendTo="body"
  #pendingMenu
  [popup]="true"
  [model]="pendingActions"
>
</p-menu>
<p-menu appendTo="body" #reviewedMenu [popup]="true" [model]="reviewedActions"></p-menu>
