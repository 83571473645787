<div class="dynamic-dialog">
  <div class="px-0">
    <div class="p-py-4" fxLayoutAlign="start">
      <div class="setup-header-title">
        <h4>
          Return Rate Review
        </h4>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <div fxFlex="100%" class="form-container">
      <div class="form-container">
        <form [formGroup]="returnRateReviewForm" autocomplete="off" novalidate>
          <div class="p-fluid p-formgrid p-grid gap-3">
            <div style="background-color: #EFF3F8;padding:0.5em" class="p-field p-col-12 gap-3  p-formgroup-inline">
              <button
                pButton
                style="width:13em;"
                pTooltip="View Attachment"
                tooltipPosition="left"
                icon="pi pi-download"
                iconPos="right"
                label="View Attachment"
                class="p-button-text"
                (click)="download(project)"
              ></button>
            </div>
            <div class="p-field p-col-12 p-formgroup-inline">
              <div class="p-pr-2">Approve Extension ?</div>
              <div *ngFor="let s of status" class="p-field-radiobutton p-flex">
                <p-radioButton
                  formControlName="status"
                  [inputId]="s.key"
                  name="status"
                  [value]="s.key"
                ></p-radioButton>
                <label [for]="s.key">{{s.name}}</label>
              </div>
            </div>
            <div class="p-field p-col-12">
              <span class="p-float-label">
                <textarea id="renark" formControlName="remark" rows="5" cols="30" pInputTextarea></textarea>
                <label for="renark">Remarks</label>
            </span>
            </div>
          </div>
          <div
            class="p-mb-0 p-pb-0"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            <button
              pButton
              type="button"
              iconPos="left"
              icon="pi pi-times"
              label="Close"
              class="p-button-text p-button-sm mx-3"
              (click)="close()"
            ></button>
            <button
              pButton
              type="button"
              label="Submit"
              [disabled]="returnRateReviewForm.invalid"
              icon="pi pi-save"
              iconPos="left"
              (click)="saveReview()"
            ></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '50%'}" key="extension" appendTo="body"  [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
