<div class="p-field tree-input" fxFlex>
  <span class="p-float-label p-input-icon-right">
    <input
      fxFlex
      id="description"
      type="text"
      class="p-inputtext-sm"
      autocomplete="off"
      pInputText
      style="cursor: pointer"
      (click)="disabled?disabled:op.toggle($event)"
      [ngModel]="selectedValue?.label"
    />
    <label for="description">{{ label }}</label>
    <i class="pi pi-chevron-down"></i>
    <div
      style="
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      "
      (click)="disabled?disabled:op.toggle($event)"
    ></div>
  </span>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
  <ng-template pTemplate>
    <p-tree
      [value]="nodes!"
      [filter]="true"
      [propagateSelectionUp]="false"
      (onNodeExpand)="nodeExpand($event)"
      [selectionMode]="selectionMode"
      [(selection)]="selectedValue"
      [loading]="treeLoading"
      (onNodeSelect)="onSelectionChange($event)"
    ></p-tree>
  </ng-template>
</p-overlayPanel>
