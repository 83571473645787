import {
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, Subscription } from 'rxjs';
import {AdminHierarchy} from "./admin-hierarchy.model";
import {UserService} from "../../services/user.service";
import {UserStorageService} from "../../services/user-storage.service";
import {CouncilManagementService} from "../../services/council-management.service";
import {LocalStorageService} from "../../services/local-storage.service";


@Component({
  selector: 'app-admin-hierarchy-tree',
  templateUrl: './admin-hierarchy-tree.component.html',
  styleUrls: ['./admin-hierarchy-tree.component.scss']
})
export class AdminHierarchyTreeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  currentUser!: any;
  treeLoading: boolean = false;
  nodes: TreeNode[] | null = [];
  rootAdminHierarchy: AdminHierarchy = new AdminHierarchy();
  selectedValue: any;

  @Input() selectionSubject?: Subject<TreeNode>;

  @Input() selectionMode: string = 'single';
  @Input() initAdminHierarchy?: AdminHierarchy;
  @Input() returnType: string = 'id';
  @Input() disabled: boolean = false;
  @Input() stateKey?: string;
  @Input() label: string = 'Admin Hierarchy';
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('op') panel!: OverlayPanel;
  subscription?: Subscription;

  constructor(
    protected userService: UserService,
    private _storageSvc: UserStorageService,
    private _councilSvc: CouncilManagementService,
    protected localStorageService: LocalStorageService
  ) {
    this.currentUser = this._storageSvc.userInfo();
  }

  ngOnInit(): void {
    if (this.selectionSubject) {
      this.subscription = this.selectionSubject?.subscribe((value) => {
        this.selectedValue = value;
      });
    }
    setTimeout(() => {
      if(this.initAdminHierarchy !== undefined){
        this.processTree(this.initAdminHierarchy);
      }else{

        this.rootAdminHierarchy.id = this.currentUser.administrativeAreaId;
        this.rootAdminHierarchy.name = this.currentUser.area;
        this.rootAdminHierarchy.areaTypeId = this.currentUser.areaTypeId;
        //
        this.processTree(this.rootAdminHierarchy!);
      }
    }, 1000);

  }

  processTree(rootAdminHierarchy:AdminHierarchy){
    const history = this.localStorageService.getItem(`${this.stateKey}_state`);
    // @ts-ignore
    if (this.stateKey && history &&rootAdminHierarchy?.id?.toString() === history[0].key
    ) {
      this.nodes = this.localStorageService.getItem(`${this.stateKey}_state`);
      // @ts-ignore
      this.selectedValue = this.localStorageService.getItem(`${this.stateKey}_state`
        `${this.stateKey}_selected`
      );
    } else {
      if (rootAdminHierarchy) {
        this.nodes = [
          {
            label: rootAdminHierarchy.name,
            data: rootAdminHierarchy,
            key: rootAdminHierarchy.id?.toString(),
            children: [],
            leaf: false,
          },
        ];
        this.selectedValue = this.nodes[0];
      }
    }
  }

  ngAfterViewInit(): void {
    if(this.currentUser?.admin_hierarchy !== undefined){
      this.onSelectionChange();
    }
  }

  nodeExpand(event: any): any {
    let selected: TreeNode = event.node;
    // this.treeLoading = true;
    //get regions
    if (this.currentUser.areaTypeId == 0){
       this._councilSvc.getRegions(26,'id').subscribe(resp => {
        selected.children = resp.data.itemList?.map((a:any) => {
          return {
            label: a.name+' '+a.typeName,
            key: a.id?.toString(),
            data: a,
            leaf: false,
            parent: undefined,
          };
        });
      })
    }
    //get councils
    if (this.currentUser.areaTypeId == 1 || selected.data.type == 1 ){
       this._councilSvc.getCouncilByRegion(185,'id',selected.data.id).subscribe(resp => {
        selected.children = resp.data.itemList?.map((a:any) => {
          return {
            label: a.name+' '+a.typeName,
            key: a.id?.toString(),
            data: a,
            leaf: false,
            parent: undefined,
          };
        });
      })
    }
    //get wards
    if (this.currentUser.areaTypeId == 4 ||
      this.currentUser.areaTypeId == 5 ||
      this.currentUser.areaTypeId == 6 ||
      this.currentUser.areaTypeId == 7 ||
      selected.data.type == 4 ||
      selected.data.type == 5 ||
      selected.data.type == 6 ||
      selected.data.type == 7
    ){
       this._councilSvc.getWardsByCouncilId(20,'id',selected.data.id).subscribe(resp =>{
        selected.children = resp.data.itemList?.map((a:any) => {
          return {
            label: a.name+' '+a.typeName,
            key: a.id?.toString(),
            data: a,
            leaf: false,
            parent: undefined,
          };
        });
      });
    }
    //get village and or mitaa
    if (selected.data.type == 13){
      this._councilSvc.getVillagesByWardId(80,'id',selected.data.id).subscribe(resp =>{
        selected.children = resp.data.itemList?.map((a:any) => {
          return {
            label: a.name+' '+a.typeName,
            key: a.id?.toString(),
            data: a,
            leaf: false,
            parent: undefined,
          };
        });
      });
    }
  }


  private cleanNodes(nodes: TreeNode[]): TreeNode[] {
    return nodes.map((n) => {
      return {
        ...n,
        parent: undefined,
        children: n.children ? this.cleanNodes(n.children) : [],
      };
    });
  }

  onSelectionChange(event?: any): void {
    setTimeout(() => {
      const selection =
        typeof this.selectedValue === 'object'
          ? this.returnType === 'object'
            ? this.selectedValue?.data
            : this.selectedValue?.data?.id
          : this.selectedValue?.data?.map((d: AdminHierarchy) => {
            return this.returnType === 'object' ? d : d.id;
          });
      this.onSelect.next(selection);
      this.panel.hide();
      this.localStorageService.setItem(`${this.stateKey}_selected`, {
        label: this.selectedValue.label,
        data: this.selectedValue.data,
        leaf: this.selectedValue.leaf,
      });
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
