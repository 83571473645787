import { Component, OnInit } from '@angular/core';
import {MenuItem} from "primeng/api";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {InteractionService} from "../../../shared/services/interaction.service";
import {PermissionFilteringService} from "../../../shared/services/permission-filtering.service";
import {ExtensionRequestReviewComponent} from "../../views/extension-request-review/extension-request-review.component";
import {ExtensionDetailsComponent} from "../../views/extension-details/extension-details.component";
import {PdfViewerComponent} from "../../../shared/components/pdf-viewer/pdf-viewer.component";
import {Menu} from "primeng/menu";
import {ReturnRateReviewComponent} from "../../views/return-rate-review/return-rate-review.component";

@Component({
  selector: 'tausi-return-project-plot',
  templateUrl: './return-project-plot.component.html',
  styleUrls: ['./return-project-plot.component.scss']
})
export class ReturnProjectPlotComponent implements OnInit {
  projectPendingReturn: any[] = [];
  projectReviewedReturn: any[] = [];
  projectRejectedReturn: any[] = [];
  public pendingActions!: any[];
  public reviewedActions!: MenuItem[];
  activeIndex = 0;
  totalRecords: any;
  params = {
    pageSize: 10,
    pageNo: 0,
  };
  status: number = 1;
  constructor(
    public _ref: DynamicDialogRef,
    public _dialogSvc: DialogService,
    public _landSalesSvc: LandSalesService,
    private _interactionSvc: InteractionService,
    private _permissionFilteringService: PermissionFilteringService
  ) {}


  ngOnInit(): void {
    this.pendingActions = [
      {
        label: 'Review',
        icon: 'pi pi-clock',
        permissions: ['LSS_RETURN_LAND_PROJECT_REVIEW'],
        command: (event: any) => {
          this.reviewRequest(event.item.data);
        },
      },
    ];
    this.pendingActions = this._permissionFilteringService.filterByNodes(
      this.pendingActions
    );

    this.reviewedActions = [
      {
        label: 'View Details',
        icon: 'pi pi-list',
        command: (event: any) => {
          this.viewDetails(event.item.data);
        },
      },
    ];
    setTimeout(() => {
      this.fetchProjectReturn();
    });
  }

  reviewRequest(project: any) {
    const ref = this._dialogSvc.open(ReturnRateReviewComponent, {
      showHeader: false,
      width: '40%',
      baseZIndex: 10000,
      data: project.landProject
    });
    ref.onClose.subscribe((response) => {
      if (response) {
        this.fetchProjectReturn();
      }
    });
  }

  viewDetails(project: any) {
    const ref = this._dialogSvc.open(ExtensionDetailsComponent, {
      showHeader: false,
      width: '40%',
      baseZIndex: 10000,
      data: project,
    });
    ref.onClose.subscribe((response) => {
      if (response) {
        this.fetchProjectReturn();
      }
    });
  }

  download(item: any) {
    this._landSalesSvc
      .getPlotReturnAttachment(item.id)
      .subscribe((response) => {
        if (response.statusCode === '21000') {
          const attachment = response.data.item;
          let item = { attachment: attachment, type: 'base64' };

          const viewerRef = this._dialogSvc.open(PdfViewerComponent, {
            header: 'Print Bill',
            showHeader: true,
            width: '70%',
            contentStyle: { 'min-height': '900px' },
            data: item,
          });
        } else {
          this._interactionSvc.dialog(
            response.description,
            'pi-exclamation-triangle warning'
          );
        }
      });
  }

  togglePendingMenu(menu: Menu, event: Event, row: any) {
    this.pendingActions.forEach((item: any) => {
      item.data = row;
    });
    menu.toggle(event);
  }

  toggleReviewedMenu(menu: Menu, event: Event, row: any) {
    this.reviewedActions.forEach((item: any) => {
      item.data = row;
    });
    menu.toggle(event);
  }
  fetchProjectReturn() {
    this.status = this.activeIndex + 1;
    this.projectReviewedReturn = [];
    this.projectRejectedReturn = [];
    this.projectPendingReturn = [];
    this._landSalesSvc
      .getPlotReturnRequest(
        this.status,
        this.params.pageNo,
        this.params.pageSize
      )
      .subscribe((response) => {
        if (response.statusCode === '21000') {
          if (this.status == 1) {
            this.projectPendingReturn = response.data.itemList;
          } else if (this.status == 2) {
            this.projectReviewedReturn = response.data.itemList;
          } else {
            this.projectRejectedReturn = response.data.itemList;
          }
          const totalPage = response?.data?.totalPage;
          this.totalRecords = totalPage * 10;
        } else {
          this._interactionSvc.dialog(
            response.description,
            'pi-exclamation-triangle warning'
          );
        }
      });
  }

  pageChange(event: any) {
    this.params.pageNo = event.first! / event.rows!;
    this.params.pageSize = event.rows;
    this.fetchProjectReturn();
  }

  reviewedPageChange(event: any) {
    this.params.pageNo = event.first! / event.rows!;
    this.params.pageSize = event.rows;
    this.fetchProjectReturn();
  }

  rejectedPageChange(event: any) {
    this.params.pageNo = event.first! / event.rows!;
    this.params.pageSize = event.rows;
    this.fetchProjectReturn();
  }

}
