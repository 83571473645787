import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private _gfscodeSource = new Subject<boolean>();
  private _projectionsSource = new Subject<boolean>();
  private _summarySource = new Subject<boolean>();

  private _initialSource = new Subject<boolean>();
  private _councilSource = new Subject<boolean>();
  private _institutionSource = new Subject<boolean>();
  private _villageSource = new Subject<boolean>();

  private _detailSource = new Subject<boolean>();
  private _contractSource = new Subject<boolean>();
  private _sourceSource = new Subject<boolean>();

  private _activeTabSource = new Subject<string>();
  private _progressSource = new Subject<number>();
  private _editorSource = new Subject<boolean>();

  private _collectorFormSource = new Subject<boolean>();

  public sideNavState$: Subject<boolean> = new Subject();

  statusInitial$ = this._initialSource.asObservable();
  statusCouncil$ = this._councilSource.asObservable();
  statusInstituion$ = this._institutionSource.asObservable();
  statusVillage$ = this._villageSource.asObservable();

  statusDetail$ = this._detailSource.asObservable();
  statusContract$ = this._contractSource.asObservable();
  statusSource$ = this._sourceSource.asObservable();

  activeTab$ = this._activeTabSource.asObservable();
  progressValue$ = this._progressSource.asObservable();

  currentEditor$ = this._editorSource.asObservable();
  statusGFSCode$ = this._gfscodeSource.asObservable();
  statusProjection$ = this._projectionsSource.asObservable();
  statusSummary$ = this._summarySource.asObservable();

  public isloading = new Subject<boolean>();

  constructor(private _confirmSvc: ConfirmationService) {}

  /**
   * sets current editor in notification
   * @param status
   */
  setEditor(status: boolean) {
    this._editorSource.next(status);
  }

  /**
   * this will keep track of an observable on initial modal
   * takes status
   * @param status
   */
  setInitial(status: boolean) {
    this._initialSource.next(status);
  }

  /**
   * this will keep track of an observable on council form
   * takes status
   * @param status
   */
  setCouncil(status: boolean) {
    this._councilSource.next(status);
  }

  /**
   * this will keep track of an observable on institution form
   * takes status
   * @param status
   */
  setInstitution(status: boolean) {
    this._institutionSource.next(status);
  }

  /**
   * this will keep track of an observable on village form
   * takes status
   * @param status
   */
  setVillage(status: boolean) {
    this._villageSource.next(status);
  }

  /**
   * sets tabname
   * @param tabName
   */
  setActiveTab(tabName: string) {
    this._activeTabSource.next(tabName);
  }

  /**
   * set progress bar value
   * @param value
   */
  setProgressValue(value: number) {
    this._progressSource.next(value);
  }

  showloader() {
    this.isloading.next(true);
  }
  hideloader() {
    this.isloading.next(false);
  }

  closeAllforms() {
    this.setInitial(true);
    this.setCouncil(false);
    this.setVillage(false);
    this.setInstitution(false);
  }

  setGFSCode(value: boolean) {
    this._gfscodeSource.next(value);
  }

  setProjections(value: boolean) {
    this._projectionsSource.next(value);
  }

  /**
   * sets and usets the view of detail view
   * @param status
   */
  setDetail(status: boolean) {
    this._detailSource.next(status);
  }

  /**
   * sets and usets the view of source view
   * @param status
   */
  setSource(status: boolean) {
    this._sourceSource.next(status);
  }

  /**
   * sets and usets the view of contract view
   * @param status
   */
  setContract(status: boolean) {
    this._contractSource.next(status);
  }

  /**
   * sets and unsets the view of contract view
   * @param status
   */
  setSummary(status: boolean) {
    this._summarySource.next(status);
  }

  /**
   * show a informational dialog
   * @param message: string
   * @param icon: string
   */
  dialog(message: string, icon: string, acceptLabel = 'Yes',header='header'): void {
    this._confirmSvc.confirm({
      key: 'confirm',
      message: message,
      icon: `pi ${icon}`,
      header: header,
      rejectVisible: false,
      acceptLabel: acceptLabel,
      rejectLabel: 'No',
      rejectButtonStyleClass: 'p-button-text p-mx-3',
      acceptButtonStyleClass: 'p-button',
    });
  }
}
